// @ts-nocheck
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { change, submit } from 'redux-form';
import { AREA_TIMEOUT_TIME_UNIT, BLUE, MATCH_TAG_MODE } from '../../_config/consts';
import * as AreaActions from '../../redux/actions/area.actions';
import * as TagActions from '../../redux/actions/tag.actions';
import MDButton from '../MDButton/MDButton.jsx';
import AreaDetailsView from './AreaDetailsView.jsx';

const timeUnitsOptions = [
  {
    entity: AREA_TIMEOUT_TIME_UNIT.minutes,
    value: AREA_TIMEOUT_TIME_UNIT.minutes,
  },
  {
    entity: AREA_TIMEOUT_TIME_UNIT.hours,
    value: AREA_TIMEOUT_TIME_UNIT.hours,
  },
  {
    entity: AREA_TIMEOUT_TIME_UNIT.days,
    value: AREA_TIMEOUT_TIME_UNIT.days,
  },
];

const theme = createTheme({
  palette: {
    primary: { 500: BLUE },
  },
});
@connect(state => ({
  form: state.form.AreaDetailsViewForm,
  area: state.areas.selectedArea,
  language: state.settings.language,
  themeName: state.settings.items.theme.data.themeName,
  lockTags: state.tags.lock.data,
}))
class AreasOperationalSection extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      
    };
  }

  componentWillMount() {
    const { dispatch } = this.props;
    const includeSpecialTags = true;
    dispatch(TagActions.cancelFetchLockTagsByFilter());
    dispatch(TagActions.fetchLockTags(includeSpecialTags, 0, 100));
  }

  filterLockTags(value) {
    const { dispatch } = this.props;
    const includeSpecialTags = true;
    dispatch(TagActions.cancelFetchLockTagsByFilter());
    dispatch(TagActions.fetchLockTagsByFilter(value, 100, includeSpecialTags));
  }

  fetchLocksByTags(tags = [], page = 0, lockTagMatchingMode = MATCH_TAG_MODE.EVERY_TAG, append = false) {
    const { dispatch } = this.props;
    dispatch(AreaActions.cancelFetchLocksByTags());
    dispatch(AreaActions.fetchLocksByTags(tags, lockTagMatchingMode, page, append));
    dispatch(change('AreaDetailsViewForm', 'resourceSmartLockTags', tags));
  }

  async onSave(areaData) {
    const { dispatch, form, area, onAreaEditConfirmed, onAreaCreateConfirmed } = this.props;

    if (!areaData.smartLocksInIds || areaData.smartLocksInIds.length==0)
      return;
    if (!areaData.smartLocksOutIds || areaData.smartLocksOutIds.length==0)
      return;

    let areaDataParsed = structuredClone(areaData)
    const timeout = areaDataParsed.timeoutNumber?areaDataParsed.timeoutNumber:0;

    if (areaDataParsed.timeoutTimeUnit===timeUnitsOptions[2].value)
      areaDataParsed.timeout =  timeout*24*60;
    else if (areaDataParsed.timeoutTimeUnit===timeUnitsOptions[1].value)
      areaDataParsed.timeout =  timeout*60;
    else
      areaDataParsed.timeout =  timeout;

    if (!area||_.isEmpty(area)||area.id===undefined)
      onAreaCreateConfirmed(areaDataParsed)
    else
      onAreaEditConfirmed(area.id,areaDataParsed)
  }

  render() {
    const {
      dispatch,
      area,
      form,
      lockTags,
    } = this.props;

    return (
      <MuiThemeProvider theme={theme}>
        <div>
          <AreaDetailsView
            area={area}
            onSubmit={(areaData)=>this.onSave(areaData)}
            timeUnitsOptions={timeUnitsOptions}
            locksFilteredByTags={area && area.locksFilteredByTags ? area.locksFilteredByTags[0] : []}
            availableLockTags={lockTags}
            onLockInputChange={value => this.filterLockTags(value)}
            onLockTagsChange={values => this.fetchLocksByTags(values, 0, form && form.values && form.values.lockTagMatchingMode)}
            onFetchMoreLocks={page => this.fetchLocksByTags(form && form.values && form.values.resourceSmartLockTags, page, form && form.values && form.values.lockTagMatchingMode, true)}
            onLockFilterModeChange={mode => this.fetchLocksByTags(form && form.values && form.values.resourceSmartLockTags, 0, mode)}
          />
          <MDButton
            title={<Entity entity="save" />}
            containerStyle={{ position: 'absolute', left: 0, right: 0, bottom: 0, width: '100%', margin: 0 }}
            style={{ height: 45, borderRadius: 0 }}
            onClick={() => dispatch(submit('AreaDetailsViewForm'))}
          />
        </div>
      </MuiThemeProvider>
    );
  }
} 

export default AreasOperationalSection;
