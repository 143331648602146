import IconButton from '@material-ui/core/IconButton';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import qs from 'qs';
import Cancel from '@material-ui/icons/Cancel';
import HyperGateIcon from '@material-ui/icons/Cast';
import ListViewIcon from '@material-ui/icons/ViewList';
import CardViewIcon from '@material-ui/icons/ViewStream';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { GATEWAYS_NOTIFICATION_PAYLOAD_TYPES, GATEWAYS_NOTIFICATION_STATUSES, GATEWAYS_NOTIFICATION_TYPES, HYPERGATE_LANDING_PAGE, LANGUAGES, LICENSE_TYPES, VIEW_MODES } from '../_config/consts';
import { saveDataToLocalStorage } from '../_config/utils.js';
import GatewayDetails from '../components/Gateways/GatewayDetails.jsx';
import GatewayNotificationStatusView from '../components/Gateways/GatewayNotificationStatusView/GatewayNotificationStatusView.jsx';
import NotificationStatusProgressBar from '../components/Gateways/GatewayNotificationStatusView/NotificationStatusProgressBar.jsx';
import GatewaysListView from '../components/Gateways/GatewaysListView.jsx';
import GatewaysTableView from '../components/Gateways/GatewaysTableView.jsx';
import LockRemoteOpenView from '../components/Locks/LockRemoteOpenView.jsx';
import BasicModal from '../components/Modals/BasicModal.jsx';
import OperationalView from '../components/OperationalView/OperationalView.jsx';
import PresentationalViewHeader from '../components/PresentationalView/PresentationalViewHeader.jsx';
import AbilityProvider from '../permissionsUtils/AbilityProvider.js';
import * as GatewaysActions from '../redux/actions/gateways.actions';
import * as LockActions from '../redux/actions/lock.actions';
import * as ModalActions from '../redux/actions/modal.actions';
import * as UtilsActions from '../redux/actions/utils.actions';
import { initialize } from 'redux-form';

let filterTimeout;
let pollingTiming;
@connect((state) => ({ gateways: state.gateways, user: state.user, routing: state.router, viewLoading: state.utils.viewLoading, language: state.settings.language, themeName: state.settings.items.theme.data.themeName }))
class Gateways extends React.Component {
  constructor(props) {
    super(props);
    const cachedViewMode = localStorage.getItem('gatewaysViewMode');
    this.state = {
      activeTab: cachedViewMode && cachedViewMode === VIEW_MODES.CARDS ? 1 : 0,
    }
  }

  componentWillMount() {
    const { dispatch, routing } = this.props;
    dispatch(GatewaysActions.setSelectedGateway({}));
    dispatch(GatewaysActions.resetGatewaysFilters());
    const parsed = qs.parse(routing.location.search, { ignoreQueryPrefix: true });
    const { gatewayId } = parsed;
    if (gatewayId) {
      this.onSelectGateway({ id: gatewayId });
    } else {
      this.fetchGateways();
    }
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(GatewaysActions.setSelectedGateway({}));
    dispatch(GatewaysActions.resetGatewaysFilters());
    dispatch(GatewaysActions.setOperationalMode(false));
  }

  async fetchGateways() {
    const { dispatch } = this.props;
    dispatch(UtilsActions.setViewLoading(true));
    try {
      await dispatch(GatewaysActions.fetchGatewaysWithDetails());
      dispatch(UtilsActions.setViewLoading(false));
    } catch(error) {
      dispatch(UtilsActions.setViewLoading(false));
    }
  }

  async filterGateways(filterValue) {
    const { dispatch } = this.props;
    dispatch(GatewaysActions.setGatewaysFilter('name', _.trim(filterValue)));
    if (filterTimeout) clearTimeout(filterTimeout);
    filterTimeout = setTimeout(async () => {
      await this.fetchGateways();
    }, 500);
  }


  closeOperationalSection() {
    const { dispatch } = this.props;
    dispatch(GatewaysActions.setOperationalMode(false));
    dispatch(GatewaysActions.setSelectedGateway({}));
  }

  openOperationalSection() {
    const { dispatch } = this.props;
    dispatch(GatewaysActions.setOperationalMode(true));
  }

  async openLock(lock) {
    const { dispatch } = this.props;
    try {
      dispatch(UtilsActions.setSpinnerVisibile(true));
      dispatch(LockActions.selectLock(lock));
      dispatch(UtilsActions.setSpinnerVisibile(false));
      this.lockOpenModal.open();
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisibile(false));
    }
  }

  onRestartGateway() {
    const { dispatch } = this.props;
    const params = {
      modalType: 'CONFIRM_TO_CONTINUE_MODAL',
      modalProps: {
        title: 'confirmBeforeContinue',
        message: 'restartGatewayConfirmation',
        onConfirmText: <Entity entity="yes" />,
        onConfirm: () => this.onRestartGatewayConfirm(),
        onCancelText: <Entity entity="no" />,
        onCancel: () => dispatch(ModalActions.hideModal()),
      },
    };
    dispatch(ModalActions.showModal(params));
  }

  async onRestartGatewayConfirm() {
    const { dispatch, gateways: { selectedGateway } } = this.props;
    dispatch(ModalActions.hideModal());
    try {
      const notificationDTO = {
        data: '',
        payloadType: GATEWAYS_NOTIFICATION_PAYLOAD_TYPES.GATEWAY_RESTART,
        type: GATEWAYS_NOTIFICATION_TYPES.COMMAND,
      };
      const notification = await dispatch(GatewaysActions.sendGatewayNotification(selectedGateway.id, notificationDTO));
      dispatch(GatewaysActions.setSelectedGatewayNotification(notification));
      this.gatewayNotificationStatusModal.open();
      this.onPollingForNotificationResponse(selectedGateway.id, notification);
    } catch (error) {
    }
  }

  onForceSynchronization() {
    const { dispatch } = this.props;
    const params = {
      modalType: 'CONFIRM_TO_CONTINUE_MODAL',
      modalProps: {
        title: 'confirmBeforeContinue',
        message: 'forceHyperGateSynchronization',
        onConfirmText: <Entity entity="yes" />,
        onConfirm: () => this.onForceSynchronizationConfirm(),
        onCancelText: <Entity entity="no" />,
        onCancel: () => dispatch(ModalActions.hideModal()),
      },
    };
    dispatch(ModalActions.showModal(params));
  }

  async onForceSynchronizationConfirm() {
    const { dispatch, gateways: { selectedGateway } } = this.props;
    dispatch(ModalActions.hideModal());
    try {
      const notificationDTO = {
        data: '',
        payloadType: GATEWAYS_NOTIFICATION_PAYLOAD_TYPES.CREDENTIALS_UPDATED,
        type: GATEWAYS_NOTIFICATION_TYPES.CONFIGURATION,
      };
      const notification = await dispatch(GatewaysActions.sendGatewayNotification(selectedGateway.id, notificationDTO));
      dispatch(GatewaysActions.setSelectedGatewayNotification(notification));
      this.gatewayNotificationStatusModal.open();
      this.onPollingForNotificationResponse(selectedGateway.id, notification);
    } catch (error) {

    }
  }

  async onPollingForNotificationResponse(gatewayId, notification) {
    const { dispatch } = this.props;
    if (pollingTiming) clearTimeout(pollingTiming);
    pollingTiming = setInterval(async () => {
      try {
        const notificationUpdated = await dispatch(GatewaysActions.getGatewayNotificationDetails(gatewayId, notification.id));
        dispatch(GatewaysActions.setSelectedGatewayNotification(notificationUpdated));
        if (notificationUpdated.status === GATEWAYS_NOTIFICATION_STATUSES.APPLIED || notificationUpdated.status === GATEWAYS_NOTIFICATION_STATUSES.FAILED) clearTimeout(pollingTiming);
      } catch (error) {
        this.gatewayNotificationStatusModal.close();
        dispatch(GatewaysActions.setSelectedGatewayNotification({}));
        clearTimeout(pollingTiming);
      }
    }, 2000);
  }

  onCloseGatewayNotificationStatusModal() {
    const { dispatch } = this.props;
    clearTimeout(pollingTiming);
    this.gatewayNotificationStatusModal.close();
    dispatch(GatewaysActions.setSelectedGatewayNotification({}));
  }

  onSearchReset() {
    const { dispatch } = this.props;
    dispatch(GatewaysActions.resetGatewaysData());
    dispatch(GatewaysActions.resetGatewaysFilters());
    this.fetchGateways();
  }

  async onSelectGateway(gateway) {
    const { dispatch } = this.props;
    dispatch(GatewaysActions.setSelectedGateway(gateway));
    try {
      dispatch(UtilsActions.setSpinnerVisibile(true));
      const gatewayDetailed = await dispatch(GatewaysActions.getGatewayDetails(gateway.id));
      dispatch(GatewaysActions.setSelectedGateway(gatewayDetailed));
      dispatch(initialize('GatewayNameInlineForm', { name: gateway.name }));
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(GatewaysActions.setOperationalMode(true));
    } catch (error) {
      dispatch(GatewaysActions.setSelectedGateway({}));
      dispatch(UtilsActions.setSpinnerVisibile(false));
    }
  }

  async onRefreshGateway() {
    const { dispatch, gateways: { selectedGateway: gateway } } = this.props;
    dispatch(GatewaysActions.setSelectedGateway(gateway));
    try {
      dispatch(UtilsActions.setSpinnerVisibile(true));
      const gatewayDetailed = await dispatch(GatewaysActions.getGatewayDetails(gateway.id));
      dispatch(GatewaysActions.setSelectedGateway(gatewayDetailed));
      dispatch(UtilsActions.setSpinnerVisibile(false));
    } catch (error) {
    }
  }

  onOpenGatewayLandingView() {
    const { language, user: { data: userData } } = this.props;
    const userEmail = userData && userData.email ? userData.email : '';
    if (language && language === LANGUAGES.ITALIAN) {
      window.open(`${HYPERGATE_LANDING_PAGE.it}?id=${btoa(userEmail)}`);
    } else {
      window.open(`${HYPERGATE_LANDING_PAGE.en}?id=${btoa(userEmail)}`);
    }
  }

  onGatewaysListModeChange(activeTab) {
    const { dispatch } = this.props;
    const selectedViewMode = activeTab === 0 ? VIEW_MODES.TABLE : VIEW_MODES.CARDS;
    this.setState({ activeTab });
    saveDataToLocalStorage('gatewaysViewMode', selectedViewMode);
    if (activeTab === 0) {
      dispatch(GatewaysActions.resetGatewaysPaginationData());
      dispatch(GatewaysActions.fetchGatewaysWithDetails(0));
    }
  }

  onFetchGatewaysForPage(page) {
    const { dispatch } = this.props;
    dispatch(GatewaysActions.fetchGatewaysWithDetails(page));
  }

  onRefreshGateways() {
    const { dispatch } = this.props;
    const { gateways } = this.props;
    const page = gateways && gateways.data && gateways.data.pagination && gateways.data.pagination.number;
    dispatch(GatewaysActions.fetchGatewaysWithDetails(page));
  }

  onOrderChanged() {
    const { dispatch } = this.props;
    dispatch(GatewaysActions.fetchGatewaysWithDetails())
  }

  async onModifyGatewayName(values) {
    const { dispatch, gateways: { selectedGateway } } = this.props;
    try {
      const updatedGateway = await dispatch(GatewaysActions.editGatewayDetails(selectedGateway.id, { name: values.name }));
      dispatch(GatewaysActions.setSelectedGateway(updatedGateway));
      dispatch(initialize('GatewayNameInlineForm', { name: updatedGateway.name }));
    } catch (error) {
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_MODAL',
        modalProps: { type: 'DEFAULT_ERROR', defaultMessage: <Entity entity="errorSavingLockName" /> },
      }));
      dispatch(UtilsActions.setSpinnerVisibile(false));
    }
  }

  render() {
    const { activeTab } = this.state;
    const { gateways, gateways: { selectedGateway, notifications: { selectedGatewayNotification } }, dispatch, themeName, viewLoading } = this.props;
    const gatewaysData = gateways && gateways.data && gateways.data.content;
    const numberOfGateways = gateways && gateways.data && gateways.data.pagination && gateways.data.pagination.totalElements ? gateways.data.pagination.totalElements : 0;
    const isLuckeyLite = AbilityProvider.getAbilityHelper().hasLicenseType([LICENSE_TYPES.LITE]);
    
    return (
      <div>
        <PresentationalViewHeader
          themeName={themeName}
          onFilterChange={value => this.filterGateways(value)}
          onSearchSubmit={() => this.fetchGateways()}
          onSearchReset={() => this.onSearchReset()}
          onRefresh={() => this.onSearchReset()}
          isLoading={viewLoading}
          numberOfElements={numberOfGateways}
          entitiesNumberSection={
            <div style={{ marginRight: 20, marginTop: 13, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <HyperGateIcon style={{ fontSize: 22 }} />
              <h4 style={{ marginTop: 0 }}>{numberOfGateways}</h4>
            </div>
          }
        />
        {gatewaysData && !_.isEmpty(gatewaysData) && !isLuckeyLite ? (
          <div style={{ top: 140, position: 'fixed', backgroundColor: 'white' }}>
            <Tabs
              value={activeTab}
              indicatorColor="primary"
              textColor="primary"
              onChange={(e, index) => this.onGatewaysListModeChange(index)}
            >
              <Tab icon={<ListViewIcon style={{ fontSize: 30 }} />} />
              <Tab icon={<CardViewIcon style={{ fontSize: 30 }} />} />
            </Tabs>
          </div>
        ) : null}
        {activeTab === 0 && !isLuckeyLite ? (
          <GatewaysTableView
            onFetchGatewaysOnPage={page => this.onFetchGatewaysForPage(page)}
            onSelectGateway={gateway => this.onSelectGateway(gateway)}
            onRefreshGateways={() => this.onRefreshGateways()}
            onOpenGatewayLandingView={() => this.onOpenGatewayLandingView()}
            onOrderChanged={() => this.onOrderChanged()}
          />
        ) : null}
        {activeTab === 1 || isLuckeyLite ? (
          <GatewaysListView
            selectedGateway={selectedGateway}
            listContainerStyle={{ top: isLuckeyLite ? 140 : 190 }}
            gateways={gateways.data}
            viewLoading={viewLoading}
            onSelectGateway={gateway => this.onSelectGateway(gateway)}
            onOpenGatewayLandingView={() => this.onOpenGatewayLandingView()}
          />
        ): null}
        <OperationalView
          themeName={themeName}
          isVisible={gateways.isOperationalMode}
          onClose={() => this.closeOperationalSection()}
          onRefresh={() => this.onRefreshGateway()}
          title={<Entity entity="sectionTitle" data={{ name: 'gatewayDetails' }} />}
        >
          <GatewayDetails
            onRestartGateway={() => this.onRestartGateway()}
            onForceSynchronization={() => this.onForceSynchronization()}
            onModifyGatewayName={(value) => this.onModifyGatewayName(value)}
            onOpenLock={lock => this.openLock(lock)}
          />
        </OperationalView>
        <BasicModal
          ref={(m) => { this.lockOpenModal = m; }}
          hideCloseButton
          body={
            <div>
              <LockRemoteOpenView
                onCloseModal={() => this.lockOpenModal.close()}
              />
            </div>
          }
        />
        <BasicModal
          ref={(m) => { this.gatewayNotificationStatusModal = m; }}
          hideCloseButton
          body={
            <div>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <h3 style={{ margin: 0, color: '#3f3f3f' }} ><Entity entity="sendGatewayCommandTitle" /></h3>
                <IconButton onClick={() => this.onCloseGatewayNotificationStatusModal()}>
                  <Cancel style={{ color: 'red', fontSize: 35 }} />
                </IconButton>
              </div>
              <h4 style={{ marginBottom: 20, color: '#3f3f3f' }}><Entity entity="sendGatewayCommandDescription" /></h4>
              <div className="remote-open-loading-container">
                <NotificationStatusProgressBar notification={selectedGatewayNotification} />
              </div>
              <GatewayNotificationStatusView
                notification={selectedGatewayNotification}
                onClose={() => this.onCloseGatewayNotificationStatusModal()}
              />
            </div>
          }
        />
      </div>
    );
  }
} 

export default Gateways;
