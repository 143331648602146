// @ts-nocheck
import AppBar from '@material-ui/core/AppBar';
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import UpdatedStatusIcon from '@material-ui/icons/Update';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import AbilityProvider from '../../permissionsUtils/AbilityProvider.js';
import * as CardActions from '../../redux/actions/card.actions';
import * as CredentialActions from '../../redux/actions/credential.actions';
import * as TagActions from '../../redux/actions/tag.actions';
import * as GuestActions from '../../redux/actions/guest.actions';
import * as UserActions from '../../redux/actions/user.actions';
import * as UtilsActions from '../../redux/actions/utils.actions';
import { BLUE, CARD_TYPES, PERMISSIONS, PERMISSION_ENTITIES, SUBSCRIPTION_TYPES } from '../../_config/consts';
import CardCredentialsList from '../Cards/CardCredentialList.jsx';
import CardEventsView from '../Cards/CardEventsView.jsx';
import SmartLocksEventsIconCustom from '../CustomIcons/SmartLocksEventsIconCustom.jsx';
import PinCredentialsForm from '../forms/PinCredentialsForm.jsx';
import { change } from 'redux-form';
import ClipboardIconCustom from '../CustomIcons/ClipboardIconCustom.jsx';

const theme = createTheme({
  palette: {
    primary: { 500: BLUE },
  },
});

@connect(state => ({
  selectedPin: state.cards.selectedPin,
  credential: state.credentials.selectedCredential,
  userTags: state.tags.user.data,
  lockTags: state.tags.lock.data,
  language: state.settings.language,
  userToken: state.user.token,
  companyConfigurations: state.user.companyConfigurations,
  companyInfo: state.user.companyInfo,
}))
class PinsOperationalSection extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      selectedTab: 0,
      cardTypes: CARD_TYPES,

    };
  }

  componentWillMount() {
    const { dispatch } = this.props;
    const includeSpecialTags = true;
    dispatch(TagActions.fetchGuestTags(includeSpecialTags, 'USER', 0, 100));
    dispatch(TagActions.fetchLockTags(includeSpecialTags, 0, 100));
  }

  async createPin(values) {
    const { dispatch } = this.props;
    dispatch(UtilsActions.setSpinnerVisibile(true));
    try {
      if (values.deviceBackendId) {
        await dispatch(CardActions.updatePin(values));
      } else {
        await dispatch(CardActions.createPin(values));
      }
    } finally {
      const isCommunicationPluginActive = dispatch(UserActions.userHasSubscriptions(SUBSCRIPTION_TYPES.COMMUNICATIONS));
      if (isCommunicationPluginActive && values.sharePinWithCommunication) {
        const pinToShare = {
          deviceId: values.deviceId,
          dateFrom: values.credentialTimeframe && values.credentialTimeframe.startDate ? moment(values.credentialTimeframe.startDate).valueOf() : null,
          dateTo: values.credentialTimeframe && values.credentialTimeframe.endDate ? moment(values.credentialTimeframe.endDate).valueOf() : null,
        }
        this.onSharePinWithCommunication(pinToShare);
      }
      dispatch(UtilsActions.setSpinnerVisibile(false));
    }
  }

  fetchGuestsByTags(page, append) {
    const { dispatch } = this.props;
    dispatch(CredentialActions.cancelFetchGuestsByTags());
    dispatch(CredentialActions.fetchGuestsByTagsStardardCredentialsForm('PIN', page, append));
  }

  fetchLocksByTags(page, append) {
    const { dispatch } = this.props;
    dispatch(CredentialActions.cancelFetchLocksByTags());
    dispatch(CredentialActions.fetchLocksByTagsStardardCredentialsForm('PIN', page, append));
  }

  filterGuestTags(value) {
    const { dispatch } = this.props;
    const tagsType = 'USER';
    dispatch(TagActions.cancelFetchGuestTagsByFilter());
    dispatch(TagActions.fetchGuestTagsByFilter(value, 50, true, tagsType));
  }

  filterLockTags(value) {
    const { dispatch } = this.props;
    dispatch(TagActions.cancelFetchLockTagsByFilter());
    dispatch(TagActions.fetchLockTagsByFilter(value, 200, true));
  }

  async onSaveKeyNotes(pinId, notes) {
    const { dispatch } = this.props;
    dispatch(UtilsActions.setSpinnerVisibile(true));
    try {
      await dispatch(CardActions.updatePinNotes(pinId, notes));
      dispatch(UtilsActions.setSpinnerVisibile(false));
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisibile(false));
    }
  }

  closeOperationalSection() {
    const { dispatch } = this.props;
    dispatch(CardActions.setOperationalMode(false));
  }

  appendCardCredentials(page) {
    const { dispatch, key } = this.props;
    dispatch(CardActions.fetchCardCredentials(key, page, true));
  }

  selectTab(index) {
    this.setState({ selectedTab: index });
  }

  onSharePinWithCommunication(pin) {
    const { dispatch } = this.props;
    dispatch(push(`/communications?pin=${JSON.stringify(pin)}`));
  }

  async onCreateAnonymousUserForPin(userData) {
    const { dispatch } = this.props;
    dispatch(UtilsActions.setSpinnerVisibile(true));
    try {
      const newGuest = await dispatch(GuestActions.createGuest(userData));
      dispatch(UtilsActions.setSpinnerVisibile(false));
      const guestTagId = newGuest && newGuest.userTag && newGuest.userTag.id;
      if (guestTagId) {
        await dispatch(CredentialActions.fetchGuestsByTags({ id: guestTagId }, 'AT_LEAST_ONE_TAG'));
        const guestTagDetails = await dispatch(TagActions.fetchGuestTagDetails(guestTagId));
        dispatch(change('PinCredentialsForm', 'guestTags', guestTagDetails));
      }
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisibile(false));
    }
  }

  render() {
    const { userTags, lockTags, selectedPin, credential, onDeletePinRequest, onSharePin, companyInfo } = this.props;
    const { selectedTab } = this.state;
    const canReadCredential = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.READ], PERMISSION_ENTITIES.CREDENTIAL);
    const canReadSmartLocksEvents = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.READ], PERMISSION_ENTITIES.LOG);
    const maxPinLength = companyInfo && companyInfo.pinLength;
    return (
      <MuiThemeProvider theme={theme}>
        <div>
          {selectedPin && !_.isEmpty(selectedPin) ? (
            <AppBar position="sticky" color="default">
              <Tabs
                value={selectedTab}
                indicatorColor="primary"
                textColor="primary"
                scrollable
                onChange={(e, index) => this.setState({ selectedTab: index })}
                style={{ paddingLeft: 25 }}
              >
                <Tab icon={<ClipboardIconCustom style={{ width: 26 }} />} label={<h5 style={{ margin: 0, fontWeight: 'bold' }}><Entity entity="cardInformation" /></h5>} />
                {selectedPin && selectedPin.id && canReadCredential &&
                  <Tab icon={<UpdatedStatusIcon style={{ fontSize: 26 }} />} label={<h5 style={{ margin: 0, fontWeight: 'bold' }}><Entity entity="cardStatus" /></h5>} />
                }
                {selectedPin && selectedPin.id && canReadSmartLocksEvents && (
                  <Tab icon={<SmartLocksEventsIconCustom style={{ width: 26 }} />} label={<h5 style={{ margin: 0, fontWeight: 'bold' }}><Entity entity="guestSmartLocksLogs" /></h5>} />
                )}
              </Tabs>
            </AppBar>
          ) : null}
          <div style={{ marginBottom: 500, padding: 20, display: selectedTab === 0 ? 'block' : 'none' }}>
            <PinCredentialsForm
              pin={selectedPin}
              availableUserTags={userTags}
              availableLockTags={lockTags}
              maxPinLength={maxPinLength}
              guestsFilteredByTags={credential.guestsFilteredByTags}
              locksFilteredByTags={credential.locksFilteredByTags}
              onFetchMoreLocks={page => this.fetchLocksByTags(page, true)}
              onFetchMoreGuests={page => this.fetchGuestsByTags(page, true)}
              onGuestInputChange={value => this.filterGuestTags(value)}
              onGuestTagsChange={() => this.fetchGuestsByTags()}
              onLockInputChange={value => this.filterLockTags(value)}
              onLockTagsChange={() => this.fetchLocksByTags()}
              onLockFilterModeChange={() => this.fetchLocksByTags()}
              onSubmit={values => this.createPin(values)}
              onDeleteCard={selectedPin && selectedPin.id ? () => onDeletePinRequest(selectedPin) : null}
              onSavePinNotes={(notes) => this.onSaveKeyNotes(selectedPin.id, notes)}
              onSharePinWithCommunication={pin => this.onSharePinWithCommunication(pin)}
              onSharePin={() => onSharePin(selectedPin)}
              onCreateAnonymousUserForPin={userData => this.onCreateAnonymousUserForPin(userData)}
            />
          </div>
          {selectedPin && selectedPin.id && canReadCredential && selectedTab === 1 && (
            <div style={{ padding: 20 }}>
              <CardCredentialsList
                credentials={selectedPin.credentials}
                appendCardCredentials={page => this.appendCardCredentials(page)}
              />
            </div>
          )}
          {selectedPin && selectedPin.id && (selectedTab === 2 || (!canReadCredential && selectedTab === 1)) ? (
            <CardEventsView
              card={selectedPin}
            />
          ) : null}
        </div>
      </MuiThemeProvider>
    );
  }
} 

export default PinsOperationalSection;
