import l20n, { Entity } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { LANGUAGE_DETAILS, LICENSE_TYPES } from '../../_config/consts';
import * as formatter from '../../_config/formatter';
import * as RestService from '../../_config/rest';
import { decodeUnicode, formatDomainHostNameToFriendlyName, getHelpCenterLanguagePrefix, getStoreLanguagePrefix } from '../../_config/utils';
import AbilityProvider from '../../permissionsUtils/AbilityProvider';
import {
  SAVE_COMPANY_SUBCOMPANIES,
  SAVE_LANGUAGE, SAVE_LOGO,
  SAVE_SAML_REQUEST_CONFIG,
  SAVE_SETTING, SELECT_SETTING,
  SET_SETTING_OPERATIONAL_MODE,
  SAVE_WHATS_NEW_CONTENT,
  SAVE_TROUBLESHOOTING_CONTENT,
  SAVE_WHATS_NEW_HAS_UPDATE
} from './actionTypes/setting';
import * as LocksActions from './lock.actions';
import * as ModalActions from './modal.actions';
import * as UserActions from './user.actions';
import * as LuckeyHelpCenterAPI from '../../_config/luckeyHelpCenterAPI';
import * as LuckeySofiaSiteAPI from '../../_config/luckeySofiaSiteAPI';


export function setOperationalMode(value) {
  return {
    type: SET_SETTING_OPERATIONAL_MODE,
    value,
  };
}

export function selectSetting(setting) {
  return {
    type: SELECT_SETTING,
    setting,
  };
}

export function saveSetting(setting, data) {
  return {
    type: SAVE_SETTING,
    setting,
    data,
  };
}

export function saveSAMLRequestConfig(samlRequestConfig) {
  return {
    type: SAVE_SAML_REQUEST_CONFIG,
    samlRequestConfig,
  };
}

export function saveWhatsNewContent(content) {
  return {
    type: SAVE_WHATS_NEW_CONTENT,
    content
  }
}

export function saveWhatsNewHasUpdate(hasUpdate) {
  return {
    type: SAVE_WHATS_NEW_HAS_UPDATE,
    hasUpdate
  }
}

export function saveTroubleShootingContent(content) {
  return {
    type: SAVE_TROUBLESHOOTING_CONTENT,
    content
  }
}


export function setMomentLocaleRule(language) {
  switch (language) {
    case 'en': {
      moment.updateLocale('en-GB', {
        week: {
          dow: 1, // Monday is the first day of the week.
        },
      });
      break;
    }
    case 'it': {
      moment.updateLocale('it', {
        week: {
          dow: 1, // Monday is the first day of the week.
        },
      });
      break;
    }
    default: {
      break;
    }
  }
}

export function fetchSAMLRequest() {
  return async (dispatch) => {
    const response = await RestService.getSAMLRequest();
    dispatch(saveSAMLRequestConfig(response.data));
  };
}

export function setLanguage(language) {
  return (dispatch, getState) => {
    const languageDetails = LANGUAGE_DETAILS[language];
    moment.locale(languageDetails ? languageDetails.moment : 'en');
    setMomentLocaleRule(languageDetails.moment);
    try {
      l20n.changeLocale(languageDetails.l20n);
    } catch (error) {
    }
    dispatch({
      type: SAVE_LANGUAGE,
      language,
    });
  };
}

export function fetchThemeSettings() {
  return async (dispatch) => {
    const isLuckeyLite = AbilityProvider.getAbilityHelper().hasLicenseType([LICENSE_TYPES.LITE]);
    const isLuckeyRFID = AbilityProvider.getAbilityHelper().hasLicenseType([LICENSE_TYPES.RFID]);
    try {
      const response = await RestService.fetchSettings();
      let themeName = isLuckeyLite ? 'gray' : (response.data.themeName || 'default');
      themeName =  isLuckeyRFID ? 'dark-brown' : themeName;
      if (response.data) {
        const formattedSettings = {
          themeName,
          logo: response.data.logoUrl ? RestService.getLogo(response.data.logoUrl, false) : '',
        };
        dispatch(saveSetting('theme', formattedSettings));
        return response.data;
      }
      throw new Error();
    } catch (error) {
      let themeName = isLuckeyLite ? 'gray' : 'default';
      themeName =  isLuckeyRFID ? 'dark-brown' : themeName;
      // Fallback on default theme
      const formattedSettings = {
        themeName,
        logo: '',
      };
      dispatch(saveSetting('theme', formattedSettings));
      throw error;
    }
  };
}

export function fetchCompanySettings() {
  return async (dispatch) => {
    try {
      const companyResponse = await RestService.fetchSettings();
      if (companyResponse && companyResponse.data) {
        const formattedSettings = {
          ...companyResponse.data,
        };
        // TODO RESTORE THIS
        // if (formattedSettings && formattedSettings.installationMode) {
        //   dispatch(ModalActions.showModal({
        //     modalType: 'WARNING_ALERT',
        //     modalProps: {
        //       anchorOrigin: { vertical: 'top', horizontal: 'center' },
        //       autoHideDuration: 10000000, //no autohide
        //       message: (
        //         <div style={{ display: 'flex', flexDirection: 'row' }}>
        //           <h6 className="snack-title" style={{ margin: 0 }}><Entity entity="installationModeActiveWarning" /></h6>
        //           <h6
        //             className="link-label snack-title"
        //             style={{ color: '#3f3f3f', margin: 0, marginLeft: 5, fontWeight: 'bold', textDecoration: 'underline' }}
        //             onClick={() => dispatch(push('/installers'))}
        //           >
        //             <Entity entity="settings" />
        //           </h6>
        //         </div>
        //       ),
        //     },
        //   }));
        // }
        dispatch(saveSetting('company', formattedSettings));
        return formattedSettings;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}

export function fetchCompanyNotifications() {
  return async (dispatch) => {
    try {
      const companyResponse = await RestService.fetchCompanyNotificationsSettings();
      if (companyResponse && companyResponse.data) {
        const formattedSettings = {
          ...companyResponse.data,
        };
        dispatch(saveSetting('companyNotifications', formattedSettings));
        return formattedSettings;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}

export function fetchSettingsByType(setting) {
  return (dispatch, getState) => {
    switch (setting) {
      case 'theme':
        return dispatch(fetchThemeSettings());
      case 'company':
      case 'systemSettings':
        return dispatch(fetchCompanySettings());
      case 'license':
        return dispatch(UserActions.fetchLicenseInfo());
      case 'companyNotifications':
        return dispatch(fetchCompanyNotifications());
      default:
        return Promise.reject();
    }
  };
}

export function fetchLogo(imageName, isThumbnail) {
  return (dispatch, getState) => {
    dispatch({
      type: SAVE_LOGO,
      logo: RestService.getLogo(imageName, isThumbnail),
    });
  };
}

export function updateSettings(values) {
  return async (dispatch, getState) => {
    const formattedSettings = formatter.formatOutputData(formatter.SETTING, values);
    const currentSetting = getState().settings.selectedSetting;
    try {
     const response = await RestService.updateSettings(formattedSettings);
      if (response.data) {
        if (currentSetting) {
          dispatch(fetchSettingsByType(currentSetting.name));
          dispatch(setOperationalMode(false));
          dispatch(selectSetting({}));
        }
      }
    } catch(error) {
    }
    if (values.image && !_.isString(values.image)) {
      const imageResponse = await RestService.postLogo(values.image);
      dispatch(fetchSettingsByType(currentSetting.name));
      if (imageResponse.data) {
        dispatch(fetchLogo(imageResponse.data.fileName, false));
      }
    }
  };
}

export function updateNotificationsSettings(values) {
  return async (dispatch, getState) => {
    try {
      const formattedSettings = formatter.formatOutputData(formatter.NOTIFICATION_SETTING, values);
      const response = await RestService.updateCompanyNotificationsSettings(formattedSettings);
      if (response && response.data) {
        dispatch(setOperationalMode(false));
        dispatch(selectSetting({}));
      }
    } catch(error) {
      throw error;
    }
  };
}

// TODO: Implement save of application data when backend is ready.
export function updateApplicationSettings(values) {
  return (dispatch, getState) => {
    const formattedSettings = formatter.formatOutputData(formatter.APPLICATION_SETTING, values);
    const currentSetting = getState().settings.selectedSetting;
    dispatch(fetchSettingsByType(currentSetting.name));
    dispatch(setOperationalMode(false));
  };
}


export function fetchCompanyMetrics() {
  return async (dispatch) => {
    try {
      const companyResponse = await RestService.fetchCompanyMetrics();
      if (companyResponse && companyResponse.data) {
        const formattedSettings = {
          ...companyResponse.data,
        };
        if (formattedSettings && (formattedSettings.numOfJobsPending > 5 || formattedSettings.numOfDeviceCredentialsToSync > 2)) {
          dispatch(ModalActions.showModal({
            modalType: 'WARNING_ALERT',
            modalProps: {
              anchorOrigin: { vertical: 'top', horizontal: 'center' },
              autoHideDuration: 10000000, //no autohide
              message: (
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <h6 className="snack-title" style={{ margin: 0 }}><Entity entity="jobsActiveWarningTitle" /></h6>
                </div>
              ),
            },
          }));
        }
        return formattedSettings;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}

export function saveCompanySubcompanies(subcompanies) {
  return {
    type: SAVE_COMPANY_SUBCOMPANIES,
    subcompanies,
  };
}

export function fetchCompanySubcompanies() {
  return async (dispatch, getState) => {
    try {
      const subcompaniesResponse = await RestService.fetchCompanySubcompanies();
      const { user } = getState();
      if (subcompaniesResponse && subcompaniesResponse.data) {
        const data = _.reject(subcompaniesResponse.data, { hostname: user.token.hostname });
        const formattedCompanies = _.map(data, company => ({ ...company, plantName: formatDomainHostNameToFriendlyName(company.hostname) }));
        dispatch(saveCompanySubcompanies(formattedCompanies));
        return data;
      }
    } catch (error) {
    }
  }
}

export function fetchSubcompaniesAndSharedLocks() {
  return async (dispatch, getState) => {
    try {
      const subcompanies = await dispatch(fetchCompanySubcompanies());
      if (subcompanies && !_.isEmpty(subcompanies)) {
        await dispatch(LocksActions.fetchSharedSmartLocks());
      }
    } catch (error) {
    }
  }
}


export function fetchWhatsNewArticles() {
  return async (dispatch, getState) => {
    try {
      const languagePrefix = getStoreLanguagePrefix();
      const content = await LuckeySofiaSiteAPI.fetchWhatsNewArticles(languagePrefix);
      if (content && content.data) {
        const whatsNewItemsContent = content.data;
        const whatsNewArticles = _.map(whatsNewItemsContent, (itemContent) => {
          const previewImage = itemContent && itemContent['_embedded'] && itemContent['_embedded']['wp:featuredmedia'] && itemContent['_embedded']['wp:featuredmedia'][0] ? itemContent['_embedded']['wp:featuredmedia'][0].source_url : null;
          return {
            ...itemContent,
            preview: itemContent && itemContent.excerpt && itemContent.excerpt.rendered ? itemContent.excerpt.rendered : undefined,
            previewImage,
            renderedTitle: itemContent && itemContent.title && itemContent.title.rendered ? decodeUnicode(itemContent.title.rendered) : '',
            renderedContent: itemContent && itemContent.content && itemContent.content.rendered ? itemContent.content.rendered : '',
          };
        });
        dispatch(saveWhatsNewContent(whatsNewArticles));
        const lastUpdate = _.first(whatsNewArticles) && _.first(whatsNewArticles).date;
        if (lastUpdate) {
          const whatNewStoredLastUpdate = localStorage.getItem('lastWhatsNewUpdate');
          const mustRead = whatNewStoredLastUpdate === 'undefined' || whatNewStoredLastUpdate === null|| moment(lastUpdate).isAfter(whatNewStoredLastUpdate);
          dispatch(saveWhatsNewHasUpdate(mustRead));
        }
        return content;
      }
      return [];
    } catch (error) {
      return [];
    }
  };
}


export function fetchTroubleshootingArticles() {
  return async (dispatch, getState) => {
    try {
      const languagePrefix = getStoreLanguagePrefix();
      const content = await LuckeyHelpCenterAPI.fetchTroubleshootingArticles(languagePrefix);
      if (content && content.data) {
        const troubleshootingItemsContent = content.data;
        const troubleshoortingArticles = _.map(troubleshootingItemsContent, (itemContent) => {
          const previewImage = itemContent && itemContent['_embedded'] && itemContent['_embedded']['wp:featuredmedia'] && itemContent['_embedded']['wp:featuredmedia'][0] ? itemContent['_embedded']['wp:featuredmedia'][0].source_url : null;
          return {
            ...itemContent,
            preview: itemContent && itemContent.excerpt && itemContent.excerpt.rendered ? itemContent.excerpt.rendered : undefined,
            previewImage,
            renderedTitle: itemContent && itemContent.title && itemContent.title.rendered ? decodeUnicode(itemContent.title.rendered) : '',
            renderedContent: itemContent && itemContent.content && itemContent.content.rendered ? itemContent.content.rendered : '',
          };
        });
        dispatch(saveTroubleShootingContent(troubleshoortingArticles));
        return content;
      }
      return [];
    } catch (error) {
      return [];
    }
  };
}