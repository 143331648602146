import { axiosInstance } from './rest';

export function fetchInvitations(params) {
  const restParams = {
    params,
  };
  return axiosInstance.get('/api/v2/invitations', restParams);
}

export function createInvitation(invitationDTO) {
  return axiosInstance.post('/api/v2/invitations', invitationDTO);
}

export function deleteInvitation(invitationId) {
  return axiosInstance.delete(`/api/v2/invitations/${invitationId}`);
}

export function fetchInvitationDevices(invitationId, params) {
  const restParams = {
    params,
  };
  return axiosInstance.get(`/api/v2/invitations/${invitationId}/devices`, restParams);
}
