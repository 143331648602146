import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import LockIcon from '@material-ui/icons/LockOpen';
import ListViewIcon from '@material-ui/icons/ViewList';
import CardViewIcon from '@material-ui/icons/ViewStream';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { destroy, initialize, submit } from 'redux-form';
import InstallerForm from '../components/forms/InstallerForm.jsx';
import InstallationModeView from '../components/Installers/InstallationModeView.jsx';
import InstallersListView from '../components/Installers/InstallersListView.jsx';
import InstallersTableView from '../components/Installers/InstallersTableView.jsx';
import MDButton from '../components/MDButton/MDButton.jsx';
import OperationalView from '../components/OperationalView/OperationalView.jsx';
import PresentationalViewHeader from '../components/PresentationalView/PresentationalViewHeader.jsx';
import AbilityProvider from '../permissionsUtils/AbilityProvider.js';
import * as InstallersActions from '../redux/actions/installers.actions';
import * as PlatformUserActions from '../redux/actions/platformUsers.actions';
import * as ModalActions from '../redux/actions/modal.actions';
import * as SettingActions from '../redux/actions/setting.actions';
import * as UserActions from '../redux/actions/user.actions';
import * as UtilsActions from '../redux/actions/utils.actions';
import { LICENSE_TYPES, PERMISSIONS, PERMISSION_ENTITIES, SUBSCRIPTION_TYPES, VIEW_MODES } from '../_config/consts.js';
import { localizeHelpCenterLink, saveDataToLocalStorage } from '../_config/utils.js';


let filterTimeout;
@connect(state => ({ roles: state.roles, installers: state.installers, company: state.settings.items.company.data, viewLoading: state.utils.viewLoading, themeName: state.settings.items.theme.data.themeName }))
class Installers extends React.Component {

  constructor(props) {
    super(props);
    const cachedViewMode = localStorage.getItem('installersViewMode');
    this.state = {
      selectedTags: null,
      activeTab: cachedViewMode && cachedViewMode === VIEW_MODES.CARDS ? 1 : 0,
      selectedGuestToDelete: null,
    };
  }

  async componentWillMount() {
    const { dispatch } = this.props;
    dispatch(UtilsActions.setViewLoading(true));
    dispatch(InstallersActions.setSelectedInstaller({}));
    dispatch(SettingActions.fetchSettingsByType('company'));
    this.onFetchInstallers();
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(InstallersActions.setSelectedInstaller({}));
    dispatch(InstallersActions.setOperationalMode(false));
  }

  async onFetchInstallers() {
    const { dispatch } = this.props;
    dispatch(UtilsActions.setViewLoading(true));
    try {
      await dispatch(InstallersActions.fetchInstallers());
    } finally {
      dispatch(UtilsActions.setViewLoading(false));
    }
  }

  onOrderChanged() {
    const { dispatch } = this.props;
    dispatch(InstallersActions.fetchInstallers())
  }

  onListModeChange(activeTab) {
    const { dispatch } = this.props;
    const selectedViewMode = activeTab === 0 ? VIEW_MODES.TABLE : VIEW_MODES.CARDS;
    this.setState({ activeTab });
    saveDataToLocalStorage('installersViewMode', selectedViewMode);
    if (activeTab === 0) {
      dispatch(InstallersActions.resetInstallersPaginationData());
      dispatch(InstallersActions.fetchInstallers(0));
    }
  }


  onCloseOperationalSection() {
    const { dispatch } = this.props;
    dispatch(InstallersActions.setSelectedInstaller({}));
    dispatch(InstallersActions.setOperationalMode(false));
  }

  onNewUser() {
    const { dispatch } = this.props;
    dispatch(InstallersActions.setOperationalMode(true));
    dispatch(destroy('InstallerForm'));
    dispatch(InstallersActions.setSelectedInstaller({}));
  }
  
  onFilterUsers(value) {
    const { dispatch } = this.props;
    dispatch(InstallersActions.setInstallersFilter('search', _.trim(value)));
    if (!value || (value.length >= 2)) {
      if (filterTimeout) clearTimeout(filterTimeout);
      filterTimeout = setTimeout(async () => this.onFetchInstallers(), 500);
    }
  }

  onResetUsersFilters() {
    const { dispatch } = this.props;
    dispatch(UtilsActions.setViewLoading(true));
    dispatch(InstallersActions.resetInstallersFilters());
    dispatch(InstallersActions.resetInstallersData());
    this.onCloseOperationalSection();
    this.onFetchInstallers();
  }

  onOpenUser(user) {
    const { dispatch } = this.props;
    dispatch(InstallersActions.setSelectedInstaller(user));
    dispatch(initialize('InstallerForm', user));
    dispatch(InstallersActions.setOperationalMode(true));
  }


  async onResetUserPassword(user) {
    const { dispatch } = this.props;
    try {
      dispatch(UserActions.resetPassword(user.id));
      dispatch(InstallersActions.setOperationalMode(false));
      dispatch(InstallersActions.setSelectedInstaller({}));
    } catch (error) {}
  }

  onConfirmDisableInstaller(user) {
    const { dispatch } = this.props;
    const params = {
      modalType: 'CONFIRM_TO_CONTINUE_MODAL',
      modalProps: {
        title: 'confirmBeforeContinue',
        message: 'disableManagerConfirmation',
        onConfirmText: <Entity entity="yes" />,
        onConfirm: () => this.onDisableInstaller(user),
        onCancelText: <Entity entity="no" />,
        onCancel: () => dispatch(ModalActions.hideModal()),
      },
    };
    dispatch(ModalActions.showModal(params));
  }

  async onDisableInstaller(user) {
    const { dispatch, onFetchPlatformUsers } = this.props;
    try {
      await dispatch(UserActions.disableUser(user.id));
      await dispatch(InstallersActions.fetchInstallers());
      dispatch(InstallersActions.setOperationalMode(false));
      dispatch(InstallersActions.setSelectedInstaller({}));
    } catch (error) {}
  }

  onDeleteUserRequest(userId) {
    const { dispatch, form } = this.props;
    const params = {
      modalType: 'CONFIRM_TO_CONTINUE_MODAL',
      modalProps: {
        title: 'confirmBeforeContinue',
        message: 'deletePlatformUserConfirmation',
        onConfirmText: <Entity entity="yes" />,
        onConfirm: () => this.onDeleteUserConfirm(userId),
        onCancelText: <Entity entity="no" />,
        onCancel: () => dispatch(ModalActions.hideModal()),
      },
    };
    dispatch(ModalActions.showModal(params));
  }

  async onDeleteUserConfirm(userId) {
    const { dispatch, form, selectedUser } = this.props;
    try {
      dispatch(ModalActions.hideModal('CONFIRM_TO_CONTINUE_MODAL'));
      dispatch(UtilsActions.setSpinnerVisibile(true));
      await dispatch(UserActions.deleteUser(userId));
      dispatch(InstallersActions.removeInstaller(userId));
      dispatch(InstallersActions.setOperationalMode(false));
      dispatch(InstallersActions.setSelectedInstaller({}));
      dispatch(UtilsActions.setSpinnerVisibile(false));
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisibile(false));
    }
  }

  async onFetchUserOnPage(page) {
    const { dispatch } = this.props;
    dispatch(UtilsActions.setViewLoading(true));
    try {
      await dispatch(InstallersActions.fetchInstallers(page));
    } finally {
      dispatch(UtilsActions.setViewLoading(false));
    }
  }

  onAppendUsers(page) {
    const { dispatch } = this.props;
    dispatch(InstallersActions.fetchAndAppendInstallers(page));
  }


  async onSaveInstaller(values) {
    const { dispatch } = this.props;
    try {
      dispatch(UtilsActions.setSpinnerVisibile(true));
      if (values.id) {
        await dispatch(InstallersActions.editInstaller(values));
      } else {
        await dispatch(InstallersActions.createInstaller(values));
      }
      dispatch(InstallersActions.setOperationalMode(false));
      dispatch(InstallersActions.setSelectedInstaller({}));
      dispatch(InstallersActions.fetchInstallers());
      dispatch(UtilsActions.setSpinnerVisibile(false));
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisibile(false));
    }
  }

  async onToggleInstallationMode(value) {
    const { dispatch } = this.props;
    const canToggleInstallationMode = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.UPDATE], PERMISSION_ENTITIES.COMPANY);
    if (canToggleInstallationMode) {
      try {
        await dispatch(InstallersActions.onSetInstallationMode(value));
      } catch (error) {
        dispatch(ModalActions.showModal({
          modalType: 'ERROR_ALERT',
          modalProps: {
            message: (<h6 className="snack-title"><Entity entity="toggleInstallationModeError" /></h6>),
          },
        }));
      }
    } else {
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="noPermissionError" /></h6>),
        },
      }));
    }
  }

  onConfirmResetPassword(user) {
    const { dispatch } = this.props;
    const params = {
      modalType: 'CONFIRM_TO_CONTINUE_MODAL',
      modalProps: {
        title: 'confirmBeforeContinue',
        message: 'resetPasswordConfirmation',
        onConfirmText: <Entity entity="yes" />,
        onConfirm: () => this.onResetPassword(user),
        onCancelText: <Entity entity="no" />,
        onCancel: () => dispatch(ModalActions.hideModal()),
      },
    };

    dispatch(ModalActions.showModal(params));
  }

  async onResetPassword(user) {
    const { dispatch } = this.props;
    try {
      dispatch(UserActions.resetPassword(user.id));
      dispatch(PlatformUserActions.setOperationalMode(false));
      dispatch(PlatformUserActions.setSelectedPlatformUser({}));
    } catch (error) {}
  }

  onOpenInstallersInfoPage() {
    const formattedURL = localizeHelpCenterLink('installers-users-and-installation-mode');
    window.open(formattedURL);
  }

  async onSynchronizeV364SmartLocks() {
    const { dispatch } = this.props;
    try {
      dispatch(UtilsActions.setSpinnerVisibile(true));
      await dispatch(UserActions.synchronizeV364SmartLocks());
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(ModalActions.showModal({
        modalType: 'SUCCESS_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="synchronizeV364LocksSuccess" /></h6>),
        },
      }));
    } catch (error) {
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="synchronizeV364LocksError" /></h6>),
        },
      }));
      dispatch(UtilsActions.setSpinnerVisibile(false));
    }
  }


  render() {
    const { dispatch, company, installers: { selectedUser, data: { content: installersData }, isOperationalMode }, themeName, viewLoading } = this.props;
    const { activeTab } = this.state;
    const canCreateGuest = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.CREATE], PERMISSION_ENTITIES.USER);
    const isLuckeyLite = AbilityProvider.getAbilityHelper().hasLicenseType([LICENSE_TYPES.LITE]);
    const isV364Domain = dispatch(UserActions.userHasSubscriptions(SUBSCRIPTION_TYPES.V364));
    return (
      <div>
        <PresentationalViewHeader
          themeName={themeName}
          onNewEntity={canCreateGuest ? () => this.onNewUser() : null}
          newEntityTitle="addInstallerUser"
          newEntityIconName="icon-simple-line-icons-user-following"
          isLoading={viewLoading}
          onFilterChange={value => this.onFilterUsers(value)}
          onSearchReset={() => this.onResetUsersFilters()}
          onInfo={() => this.onOpenInstallersInfoPage()}
        />
        <div className="installation-mode-view-outer-container" style={{ top: 150, position: 'fixed', width: '90%', backgroundColor: 'white' }}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <InstallationModeView
            installationMode={company && company.installationMode}
            onToggleInstallationMode={value => this.onToggleInstallationMode(value)}
          />
          {isV364Domain ? (
            <MDButton
              disabled={company && !company.installationMode}
              title={<Entity entity="synchronizeV364SmartLocks" />}
              icon={<LockIcon style={{ color: 'white', marginLeft: 10, fontSize: 20 }} />}
              onClick={() => this.onSynchronizeV364SmartLocks()}
            />
          ) : null}
          </div>
          {!isLuckeyLite && installersData && !_.isEmpty(installersData) ? (
            <Tabs
              value={activeTab}
              indicatorColor="primary"
              textColor="primary"
              onChange={(e, index) => this.onListModeChange(index)}
            >
              <Tab icon={<ListViewIcon style={{ fontSize: 30 }} />} />
              <Tab icon={<CardViewIcon style={{ fontSize: 30 }} />} />
            </Tabs>
          ) : null}
        </div>
        {activeTab === 0 && !isLuckeyLite ? (
          <InstallersTableView
            onSelectUser={user => this.onOpenUser(user)}
            onDisableUserRequest={user => this.onConfirmDisableInstaller(user)}
            onResetUserPassword={user => this.onResetUserPassword(user)}
            onDeleteUserRequest={user => this.onDeleteUserRequest(user.id)}
            onFetchInstallersOnPage={page => this.onFetchUserOnPage(page)}
            onNewInstallerUser={() => this.onNewUser()}
            onRefreshInstallers={() => this.onFetchInstallers()}
            onOrderChanged={() => this.onOrderChanged()}
          />
        ) : null}
        {activeTab === 1 || isLuckeyLite ? (
          <InstallersListView
            listContainerStyle={{ top: isLuckeyLite ? 280 : 320 }}
            viewLoading={viewLoading}
            onSelectUser={user => this.onOpenUser(user)}
            onNewUser={() => this.onNewUser()}
            onAppendUsers={page => this.onAppendUsers(page)}
          />
          ) : null}
        <OperationalView
          themeName={themeName}
          isVisible={isOperationalMode}
          onClose={() => this.onCloseOperationalSection()}
          title={<Entity entity="sectionTitle" data={{ name: 'installer' }} />}
        >
          <InstallerForm
            installer={selectedUser}
            onConfirmResetPassword={() => this.onConfirmResetPassword(selectedUser)}
            onDisableUser={() => this.onConfirmDisableInstaller(selectedUser)}
            onEnableUser={() => this.onResetUserPassword(selectedUser)}
            onDeleteUser={() => this.onDeleteUserRequest(selectedUser.id)}
            onSubmit={values => this.onSaveInstaller(values)}
          />
          <div style={{ backgroundColor: 'white', position: 'absolute', left: 0, right: 0, bottom: 0, width: '100%', margin: 0 }}>
            <MDButton
              style={{ height: 45, borderRadius: 0 }}
              containerStyle={{ position: 'absolute', left: 0, right: 0, bottom: 0, width: '100%', margin: 0 }}
              title={<Entity entity="save" />}
              onClick={() => dispatch(submit('InstallerForm'))}
            />
          </div>
        </OperationalView>
      </div>
    );
  }
} 

export default Installers;
