// Set up bookey
import l20n from '@sketchpixy/rubix/lib/L20n';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import jwt from 'jsonwebtoken';
import 'moment/locale/de';
import 'moment/locale/es';
import 'moment/locale/fr';
import 'moment/locale/it';
import 'moment/locale/nl';
import 'moment/locale/pl';
import 'moment/locale/en-gb';
import React from 'react';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import ReactDOM from 'react-dom';
import { applyMiddleware, createStore } from 'redux';
import { createLogger } from 'redux-logger';
import { createEpicMiddleware } from 'redux-observable';
import thunk from 'redux-thunk';
import { LANGUAGE_DETAILS, LANGUAGE_LOCALES } from './_config/consts';
import epics from './epics';
import reducers from './redux/reducers';
import Routes from './routes.jsx';
import './sass/main.scss';
import * as serviceWorker from './serviceWorker';
import * as AmplitudeService from './services/AmplitudeService.js';


const middlewares = [thunk];

const logger = createLogger();
const epicMiddleware = createEpicMiddleware(epics);
const history = createBrowserHistory();

middlewares.push(epicMiddleware);
middlewares.push(routerMiddleware(history));

if (process.env.NODE_ENV !== 'production') {
  middlewares.push(logger);
}

let decodedJWT;
if (localStorage.user) {
  decodedJWT = jwt.decode(localStorage.user);
}

l20n.initializeLocales({
  locales: LANGUAGE_LOCALES,
  default: decodedJWT && decodedJWT.languageType && LANGUAGE_DETAILS[decodedJWT.languageType] ? LANGUAGE_DETAILS[decodedJWT.languageType].l20n : 'en-US',
});

const store = createStore(reducers(history), applyMiddleware(...middlewares));

AmplitudeService.initialize();

ReactDOM.render(<Routes store={store} history={history} />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
