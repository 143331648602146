import errorCodes, { handleError, setExcludedErrorCodes } from '@bottega52/error-codes';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import * as BookeyAPI from '../_config/bookeyAPI';
import * as RestService from '../_config/rest';
import AbilityProvider from '../permissionsUtils/AbilityProvider';
import PermissionsParserV0 from '../permissionsUtils/PermissionsParserV0';
import * as AgreementsActions from '../redux/actions/agreements.actions';
import * as ModalActions from '../redux/actions/modal.actions';
import * as RoleActions from '../redux/actions/roles.actions';
import * as UserActions from '../redux/actions/user.actions';


setExcludedErrorCodes([errorCodes.BOOKEY_MEDIA_NOT_FOUND_CODE, errorCodes.JAGO_USER_NOT_FOUND, errorCodes.JAGO_USER_TAG_NOT_FOUND, errorCodes.JAGO_LOCK_TAG_NOT_FOUND, 11601, 10805]);

export default function requireGuestAuthentication(allowedRoles, allowedSubscriptions) {
  return Component => {
    class AuthenticatedComponent extends React.Component {
      constructor(props) {
        super(props);
        this.state = {
          authChecked: false,
        };
      }

      async componentWillMount() {
        const { dispatch } = this.props;
        this.setInterceptors();
        await dispatch(RoleActions.fetchPermissions());
        await this.checkAuth();
        this.setState({ authChecked: true });
      }

      setInterceptors() {
        if (!RestService.getRequestInterceptors().length && localStorage.token) {
          RestService.setRequestInterceptor(localStorage.token);
          BookeyAPI.setRequestInterceptor(localStorage.token);
        }

        if (!RestService.axiosInstance.interceptors.response.length) {
          RestService.axiosInstance.interceptors.response.use(
            response => response,
            error => this.handleInterceptorErrors(error),
          );

          BookeyAPI.setResponseInterceptor(
            response => response,
            error => this.handleInterceptorErrors(error),
          );
        }
      }

      handleInterceptorErrors(error) {
        const { dispatch } = this.props;
        return new Promise((resolve, reject) => {
          if (error instanceof Error) {
            const { message, config, code, response } = error;
            if (response && response.data && response.data.code && response.data.code) {
              const errorData = response.data;
              const errorType = handleError(errorData.code);
              if (errorData.code === 10120) {
                dispatch(ModalActions.showModal({
                  modalType: 'ERROR_ALERT',
                  modalProps: {
                    anchorOrigin: { vertical: 'top', horizontal: 'center' },
                    message: (<h6 className="snack-title"><Entity entity="changeDomainErrorTwoFactor" /></h6>),
                  },
                }));
              } else if (errorData.code === 10401 || errorData.code === 10801) { //Standard Device status not found
                return;
              } else if (errorData.code === 10120) {
                dispatch(ModalActions.showModal({
                  modalType: 'ERROR_ALERT',
                  modalProps: {
                    anchorOrigin: { vertical: 'top', horizontal: 'center' },
                    message: (<h6 className="snack-title"><Entity entity="changeDomainErrorTwoFactor" /></h6>),
                  },
                }));
              } else if (errorType) {
                dispatch(ModalActions.showModal({
                  modalType: 'ERROR_MODAL',
                  modalProps: { type: errorType, defaultMessage: errorData.message },
                }));
              }
            } else {
              if (message === 'Network Error') {
                dispatch(ModalActions.showModal({
                  modalType: 'ERROR_ALERT',
                  modalProps: {
                    anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                    message: (<h6 className="snack-title"><Entity entity="networkErrorMessage" /></h6>),
                  },
                }));
              } else if (code === 'ECONNABORTED') {
                dispatch(ModalActions.showModal({
                  modalType: 'ERROR_ALERT',
                  modalProps: {
                    anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                    message: (<h6 className="snack-title"><Entity entity="timeoutMessage" /></h6>),
                  },
                }));
              } else if (response && response.status === 401) {
                dispatch(ModalActions.showModal({
                  modalType: 'ERROR_ALERT',
                  modalProps: {
                    anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                    message: (<h6 className="snack-title"><Entity entity="unauthorizedMessage" /></h6>),
                  },
                }));
                dispatch(UserActions.logout());
              }
            }
          }
          reject(error);
        });
      }

      async checkAuth() {
        const { dispatch } = this.props;
        try {
          await dispatch(UserActions.setInfoFromLocalStorage());
          const { user, permissions: allPermissions } = this.props;
          const permissions = user.token.jago.permissions;
          const formattedPermissions = _.map(permissions, (k, v) => ({ id: k, name: v }));
          const abilities = PermissionsParserV0.parseArrayWithAll(formattedPermissions, allPermissions);
          AbilityProvider.getAbilityHelper().updateAbilities(abilities);
          const userHasSubscription = dispatch(UserActions.userHasSubscriptions(allowedSubscriptions));
          if (!user.isAuthenticated) {
            dispatch(UserActions.logout());
          } else if (!userHasSubscription) {
            dispatch(ModalActions.showModal({
              modalType: 'ERROR_MODAL',
              modalProps: {
                anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                message: (<h6 className="snack-title"><Entity entity="unauthorizedMessage" /></h6>),
              },
            }));
            dispatch(UserActions.logout());
          }
        } catch (error) {
          dispatch(UserActions.logout());
        }
      }

      render() {
        const { user } = this.props;
        const { authChecked } = this.state;
        return (
          <div>
            {authChecked && user.isAuthenticated === true
              ? <Component {...this.props} />
              : null
            }
          </div>
        );
      }
    }

    const mapStateToProps = state => ({
      user: state.user,
      permissions: state.roles.permissionsParsed,
      license: state.settings.items.license,
      routing: state.router,
      wallet: state.cloudCredits,
    });

    return connect(mapStateToProps)(AuthenticatedComponent);
  };
}
