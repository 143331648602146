/* eslint-disable react/sort-comp */
import { AppBar, Card, CircularProgress, IconButton } from '@material-ui/core';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import CloseIcon from '@material-ui/icons/Close';
import PinIcon from '@material-ui/icons/Apps';
import CardIcon from '@material-ui/icons/CreditCard';
import SearchIcon from '@material-ui/icons/Search';
import ErrorIcon from '@material-ui/icons/ErrorOutline.js';
import {
  FormControl
} from '@sketchpixy/rubix';
import { Entity, ctx as L20NContext } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import AbilityProvider from '../../permissionsUtils/AbilityProvider.js';
import { CARD_TYPES, CREDENTIAL_CATEGORIES, CREDENTIAL_TYPES, PERMISSIONS, PERMISSION_ENTITIES, SUBSCRIPTION_TYPES } from '../../_config/consts';
import CredentialRow from '../Credentials/CredentialRow.jsx';
import ListLoadingView from '../ListLoadingView.jsx';
import * as UserAction from '../../redux/actions/user.actions';
import { getStandardDeviceIcon } from '../../_config/utils.js';
import MDAccordion from '../MDAccordion/MDAccordion.jsx';
import MDTextInputView from '../forms/Elements/MDTextInput/MDTextInputView.js';
import GuestCredentialIconCustom from '../CustomIcons/GuestCredentialIconCustom.jsx';
import SmartPhoneKeyIconCustom from '../CustomIcons/SmartPhoneKeyIconCustom.jsx';
import KeyOutlinedIconCustom from '../CustomIcons/KeyOutlinedIconCustom.jsx';

@connect((state) => ({}))
class GuestCredentialsList extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      selectedTab: 0,
      isF9000Active: false,
      filterText: ''
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    const isF9000PluginActive = dispatch(UserAction.isF9000AddonActive());
    this.setState({ isF9000Active: isF9000PluginActive });
  }
  onSelectCredentialRule(credentialRuleId, serviceName) {
    const { dispatch } = this.props;
    if (serviceName === 'BOOKEY') {
      dispatch(push(`/reservations?reservationId=${credentialRuleId}`));
    } else {
      dispatch(push(`/credentials?credentialRuleId=${credentialRuleId}`));
    }
  }

  onSelectCardCredentialRule(deviceId) {
    const { dispatch } = this.props;
    dispatch(push(`/cards?deviceId=${deviceId}`));
  }

  onSelectPINCredentialRule(deviceId) {
    const { dispatch } = this.props;
    dispatch(push(`/pins?deviceId=${deviceId}`));
  }
  
  onSelectF90000CredentialRule(deviceId) {
    const { dispatch } = this.props;
    dispatch(push(`/hyperKeys?deviceId=${deviceId}`));
  }

  // eslint-disable-next-line class-methods-use-this
  getCredentialCategoryFromTab(tab) {
    switch (tab) {
      case 0:
        return CREDENTIAL_CATEGORIES.MOBILE;
      case 1:
        return CREDENTIAL_CATEGORIES.RFID;
      case 2:
        return CREDENTIAL_CATEGORIES.PIN;
      case 3:
        return CREDENTIAL_CATEGORIES.F9000;
      default:
        return CREDENTIAL_CATEGORIES.MOBILE;
    }
  }

  onSelectTab(tab) {
    const { fetchUserCredential, credentials, onSelectedUserCredentialListType } = this.props;
    this.setState({ selectedTab: tab });
    const credentialCategory = this.getCredentialCategoryFromTab(tab);
    console.log(credentialCategory)
    if (!credentials || !credentials[credentialCategory] || _.isEmpty(credentials[credentialCategory])) {
      fetchUserCredential(credentialCategory);
    }
    onSelectedUserCredentialListType(credentialCategory);
  }

  onAppendUserCredential(page) {
    const { appendUserCredential } = this.props;
    const { selectedTab } = this.state;
    const credentialCategory = this.getCredentialCategoryFromTab(selectedTab);
    setTimeout(() => appendUserCredential(page, credentialCategory), 300);
  }

  onFilterCredentials(text) {
    this.setState({ filterText: text });
  }

  onSearchReset() {
    this.setState({ filterText: '' });
  }

  render() {
    const { credentials, isLoading, guest } = this.props;
    const { selectedTab, isF9000Active, filterText } = this.state;
    const canReadStandardDevice = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.READ], PERMISSION_ENTITIES.STANDARD_DEVICE);
    const canReadCredentialRule = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.READ], PERMISSION_ENTITIES.CREDENTIAL_RULE);
    const weekdays = _.map(moment.weekdays(true), weekday => ({
      label: weekday,
      value: moment().isoWeekday(weekday).isoWeekday(),
    }));
    const credentialCategory = this.getCredentialCategoryFromTab(selectedTab);
    const filteredCredentials = credentials && credentials[credentialCategory] && credentials[credentialCategory].data ? _.filter(credentials[credentialCategory].data, credential => _.includes(credential.smartLock.name.toLowerCase(), filterText.toLowerCase())) : []
    const credentialData = filteredCredentials && selectedTab !== 0 ? _.groupBy(filteredCredentials, 'deviceId') : filteredCredentials;
    return (
      <div>
        <AppBar position="sticky" color="white" style={{ marginTop: 1 }}>
          <div>
            <Tabs
              value={selectedTab}
              textColor="primary"
              indicatorColor="primary"
              style={{ backgroundColor: 'white' }}
              onChange={(e, index) => this.onSelectTab(index)}
            >
              {canReadCredentialRule ? <Tab icon={<SmartPhoneKeyIconCustom style={{ width: 20 }} />} label={<h6 style={{ margin: 0, fontWeight: 'bold' }}><Entity entity="smartphone" /></h6>} /> : null}
              {canReadStandardDevice ? <Tab icon={<CardIcon style={{ fontSize: 20 }} />} label={<h6 style={{ margin: 0, fontWeight: 'bold' }}><Entity entity="cards" /></h6>} /> : null}
              {canReadStandardDevice ? <Tab icon={<PinIcon style={{ fontSize: 20 }} />} label={<h6 style={{ margin: 0, fontWeight: 'bold' }}><Entity entity="pins" /></h6>} /> : null}
              {canReadStandardDevice && isF9000Active ? <Tab icon={<KeyOutlinedIconCustom style={{ width: 20 }} />} label={<h6 style={{ margin: 0, fontWeight: 'bold' }}><Entity entity="mechatronicsKey" /></h6>} /> : null}
            </Tabs>
            <Card style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', alignSelf: 'flex-start', width: '90%', margin: 10, borderRadius: 5 }} elevation={2}>
            <FormControl
              type="text"
              value={filterText}
              placeholder={L20NContext.getSync('lockNameSearch')}
              className="form-control-noBorder"
              style={{ width: '100%', borderRadius: 5 }}
              onChange={e => this.onFilterCredentials(e.target.value)}
            />
            {filterText ? (
              <IconButton style={{ height: 20, width: 20, marginLeft: -60, marginRight: 10 }} onClick={() => this.onSearchReset()}>
                <CloseIcon />
              </IconButton>
            ) : null}
            <SearchIcon style={{ marginLeft: filterText ? 0 : -30 }} />
          </Card>
          </div>
        </AppBar>
        <div className="logs-list" style={{ position: 'relative', overflow: 'auto', height: '100vh', paddingBottom: 100, paddingLeft: 10, paddingRight: 10 }}>
          {isLoading ? (
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', position: 'absolute', zIndex: 100000, top: 150, right: 0, width: '100%' }}>
              <h4 style={{ color: '#3f3f3f' }}><Entity entity="refreshingCredentials" /></h4>
              <CircularProgress />
            </div>
          ) : null}
            {guest.withoutEmail && selectedTab === 0? (
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <ErrorIcon style={{ fontSize: 50, marginTop: 10, marginBottom: 10, color: '#3f3f3f' }} />
                <h4 style={{ fontWeight: 'bold', textAlign: 'center', color: '#3f3f3f', margin: 5 }}><Entity entity="noCredential" /></h4>
                <h4 style={{ fontWeight: 'bold', textAlign: 'center', color: '#3f3f3f', margin: 5 }}><Entity entity="setUserEmailForCredentials" /></h4>
              </div>  
            ) : (
            <div className="list-view" style={{ overflow: 'auto',overflowX: 'hidden', height: '120vh' }}>
              {credentialData && !_.isEmpty(credentialData) ? (
                <InfiniteScroll
                  initialLoad={false}
                  loadMore={() => this.onAppendUserCredential(credentials[credentialCategory].currentPage + 1)}
                  hasMore={credentials[credentialCategory].currentPage + 1 < credentials[credentialCategory].totalPages}
                  loader={<ListLoadingView />}
                  useWindow={false}
                  style={{ marginTop: 20, paddingBottom: 20, paddingRight: 25, paddingLeft: 15 }}
                >
                  <>
                  {selectedTab === 0 ? (
                    _.map(credentialData, (credential, index) =>
                      <CredentialRow
                        credential={credential}
                        options={weekdays}
                        key={credential.id}
                        onSelectCredentialRule={canReadCredentialRule ? () => this.onSelectCredentialRule(credential.credentialRuleId, credential.serviceName) : null}
                      />
                  )) : (
                    _.map(_.keys(credentialData), (credentialKey, index) => {
                      const credentials = credentialData[credentialKey];
                      const credentialDeviceType = _.first(credentials) && _.first(credentials).deviceType;
                      const icon = getStandardDeviceIcon(credentialDeviceType);
                      let typeString = credentialDeviceType === CARD_TYPES.ISEO_PIN ? 'pinOperationalTitle' : 'card'; 
                      typeString = credentialDeviceType === 'F9000' ? 'F9000' : typeString;
                      return (
                          <MDAccordion
                            elevation={3}
                            title={(
                              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', paddingLeft: 5 }}>
                                {icon}
                                <h3 style={{ margin: 0, fontWeight: 'bold', fontSize: 22, marginLeft: -10 }}><Entity key={credentialDeviceType} entity={typeString} /> #{credentialKey}</h3>
                              </div>
                            )}
                            defaultExpanded
                            containerstyle={{ marginTop: 20, marginRight: 20, marginLeft: 5 }}
                            >
                              <div style={{ paddingLeft: 10, paddingRight: 15 }}>
                                {_.map(credentials, credential => (
                                  <CredentialRow
                                    credential={credential}
                                    options={weekdays}
                                    hiddenHeader
                                    key={credential.id}
                                    onSelectCredentialRule={canReadCredentialRule ? () => this.onSelectCredentialRule(credential.credentialRuleId, credential.serviceName) : null}
                                    onSelectCardCredentialRule={canReadStandardDevice ? () => this.onSelectCardCredentialRule(credential.deviceId) : null}
                                    onSelectF90000CredentialRule={canReadStandardDevice ? () =>  this.onSelectF90000CredentialRule: null}
                                    onSelectPINCredentialRule={canReadStandardDevice ? () => this.onSelectPINCredentialRule(credential.deviceId) : null}
                                  />
                                ))}
                              </div>
                        </MDAccordion>
                      )
                    }
                  ))}
                  </>
                </InfiniteScroll>
              ) : (
                <div>
                  {!isLoading ? (
                    <div style={{ opacity: isLoading ? 0.4 : 1, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      <ErrorIcon style={{ fontSize: 50, marginTop: 10, marginBottom: 10, color: '#3f3f3f' }} />
                      <h4 style={{ fontWeight: 'bold', textAlign: 'center', color: '#3f3f3f', margin: 5 }}><Entity entity="noCredential" /></h4>
                      <h4 style={{ textAlign: 'center', margin: 0, color: '#3f3f3f' }}><Entity entity="noGuestCredentialFoundUserList" /></h4>
                    </div>
                  ) : null}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
} 

export default GuestCredentialsList;
