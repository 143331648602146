import { Card, Divider } from '@material-ui/core';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import React from 'react';
import {
  EmailIcon,
} from 'react-share';
import SupportAgentIconCustom from '../CustomIcons/SupportAgentIconCustom.jsx';

class SupportFromVar extends React.Component {

  handleMailTo = (varSupportEmail) => {
    const email = varSupportEmail;
    const subject = 'Report a problem: ';
    const body = '';
    
    // Costruisci l'URL mailto
    const mailtoURL = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;

    const link = document.createElement('a');
    link.href = mailtoURL;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  render() {
    const { companyInfo } = this.props;
    return (
      <div style={{ padding: 20, display: 'flex', flexDirection: 'column', alignItems:'center'}}>
        <SupportAgentIconCustom style={{ width: 60, margin: 'auto' }} />
        <h4 style={{ fontSize: 20, color: '#3f3f3f', textAlign: 'center', fontWeight: 'bold', marginBottom: 30, marginTop: 10 }}><Entity entity="sendTicketToSupportDescription" /></h4>
        <Divider orientation='horizontal' style={{width:'100%', marginBottom: 30,}}></Divider>
        <h4 style={{ fontSize: 18, color: '#3f3f3f', textAlign: 'center', fontWeight: 'bold', marginBottom: 20 }}>{companyInfo.varName}</h4>
        <Card style={{width:'fit-content', padding:15, display:'flex', flexDirection:'row', alignItems:'center'}} elevation={5}>
          <EmailIcon round size={36} onClick={this.handleMailTo(companyInfo.varSupportEmail)} style={{cursor:'pointer'}}/>
          <h4 style={{marginLeft:20}}>{companyInfo.varSupportEmail}</h4>
        </Card>
        <h4 style={{ fontSize: 16, color: '#3f3f3f', textAlign: 'center', fontWeight: 'bold', marginBottom: 10, marginTop: 50 }}><Entity entity="sendSupportRequestVarHours" /></h4>
        <h4 style={{}}>{companyInfo.varSupportHours}</h4>

      </div>
    );
  }
} 

export default SupportFromVar;
