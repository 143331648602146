// @ts-nocheck
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import ListViewIcon from '@material-ui/icons/ViewList';
import CardViewIcon from '@material-ui/icons/ViewStream';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import moment from 'moment';
import qs from 'qs';
import React from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { initialize, submit } from 'redux-form';
import { CREDENTIAL_CATEGORIES, EXPORT_FORMATS, LICENSE_TYPES, MATCH_TAG_MODE, MOBILE_UDID_STATES, PERMISSIONS, PERMISSION_ENTITIES, VIEW_MODES } from '../_config/consts';
import { getResidualElementFromLicense } from '../_config/utils.js';
import UsersIconCustom from '../components/CustomIcons/UsersIconCustom.jsx';
import GuestAddCSVMessage from '../components/Guests/GuestAddCSVMessage.jsx';
import GuestDeleteView from '../components/Guests/GuestDeleteView.jsx';
import GuestTableView from '../components/Guests/GuestTableView.jsx';
import GuestsDeleteBatchError from '../components/Guests/GuestsDeleteBatchError.jsx';
import GuestsDeleteBatchView from '../components/Guests/GuestsDeleteBatchView.jsx';
import GuestsListView from '../components/Guests/GuestsListView.jsx';
import GuestsOperationalSection from '../components/Guests/GuestsOperationalSection.jsx';
import ImportGuestsCSV from '../components/Guests/ImportGuestsCSV.jsx';
import BasicModal from '../components/Modals/BasicModal.jsx';
import OperationalView from '../components/OperationalView/OperationalView.jsx';
import PresentationalViewHeader from '../components/PresentationalView/PresentationalViewHeader.jsx';
import TranslatableOption from '../components/forms/Fields/TranslatableOption.jsx';
import AbilityProvider from '../permissionsUtils/AbilityProvider';
import * as AccessProfilesActions from '../redux/actions/accessProfiles.actions.js';
import * as GuestActions from '../redux/actions/guest.actions';
import * as LogEventsActions from '../redux/actions/logEvents.actions';
import * as ModalActions from '../redux/actions/modal.actions';
import * as TagsActions from '../redux/actions/tagsManagement.actions.js';
import * as UserActions from '../redux/actions/user.actions';
import * as UtilsActions from '../redux/actions/utils.actions';
import GuestsTagsBatchView from '../components/Guests/GuestsTagsBatchView.jsx';
import GuestsTagsBatchError from '../components/Guests/GuestsTagsBatchError.jsx';
import GuestEnableDisableBatchView from '../components/Guests/GuestEnableDisableBatchView.jsx';
import GuestEnableDisableBatchError from '../components/Guests/GuestEnableDisableBatchError.jsx';

let filterTimeout;
const statusFilterOption = [{ id: 0, name: 'ALL', value: 'ALL', label: 'allStatus' }, { id: 1, name: 'ACTIVE', value: true, label: 'activeGuests' }, { id: 2, name: 'DISABLED', value: false, label: 'disabledGuests' }];
@connect((state) => ({ routing: state.router, guests: state.guests, companyConfigurations: state.user.companyConfigurations, viewLoading: state.utils.viewLoading, userLicense: state.user.license, themeName: state.settings.items.theme.data.themeName, companyData: state.settings.items.company, customFields: state.customFields.users.content }))
class Guests extends React.Component {
  constructor(props) {
    super(props);
    const cachedViewMode = localStorage.getItem('guestViewMode');
    this.state = {
      selectedTags: null,
      activeTab: cachedViewMode && cachedViewMode === VIEW_MODES.CARDS ? 1 : 0,
      selectedGuestToDelete: null,
      guestListCredentialCategorySelected: CREDENTIAL_CATEGORIES.MOBILE,
      showImportCSVOperationalSection: false,
    };
  }
  async componentWillMount() {
    const { dispatch, routing } = this.props;
    const parsed = qs.parse(routing.location.search, { ignoreQueryPrefix: true });
    dispatch(GuestActions.selectGuest({}));
    dispatch(UtilsActions.setViewLoading(true));
    const isAccessProfileAddonActive = dispatch(UtilsActions.isAccessProfileAddonActive());
    if (isAccessProfileAddonActive) {
      dispatch(AccessProfilesActions.fetchAccessProfiles(0, 50));
    }
    try {
      dispatch(GuestActions.resetGuestsFilters());
      await dispatch(GuestActions.fetchGuests());
    } finally {
      dispatch(UtilsActions.setViewLoading(false));
    }
    const guestId = parsed.guestId;
    const guestEmail = parsed.guestEmail;
    const newEntity = parsed.newEntity;
    if (guestId) {
      const newGuest = await dispatch(GuestActions.getGuestDetails(guestId));
      if (newGuest) {
        this.selectGuest(newGuest);
      }
    } else if (guestEmail) {
      this.onSetFilter('search', guestEmail);
    }
    if (newEntity) {
      this.onNewUserButtonClicked();
    }
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(GuestActions.resetGuestsFilters());
    dispatch(GuestActions.selectGuest({}));
  }

  onNewUserButtonClicked() {
    const { dispatch, userLicense } = this.props;
    dispatch(GuestActions.selectGuest({}));
    const residualUsers = getResidualElementFromLicense(userLicense, 'USERS');
    if (residualUsers <= 0 && userLicense.isProfessional) {
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_MODAL',
        modalProps: {
          type: 'DEFAULT_ERROR',
          defaultMessage: <Entity entity="errorUserLimitAddUser" />,
        },
      }));
    } else {
      if (this.guestsOperationalSection) {
        this.guestsOperationalSection.getWrappedInstance().selectTab(0);
      }
      dispatch(GuestActions.setOperationalMode(true));
      // HERE SET THE COMPANY DEFAULT MOBILE UUID STATE
      const { companyData } = this.props;
      const defaultUUIDState = companyData && companyData.data && companyData.data.mobileUuidDefaultState;
      const isLuckeyRFID = AbilityProvider.getAbilityHelper().hasLicenseType([LICENSE_TYPES.RFID]);
      dispatch(initialize('GuestForm', { mobileUuidState: defaultUUIDState || MOBILE_UDID_STATES.NEVER_SET, withoutEmail: isLuckeyRFID }));
    }
  }

  async filterGuestByTag(tags) {
    const { dispatch } = this.props;
    if (tags && !_.isEmpty(tags)) {
      dispatch(UtilsActions.setViewLoading(true));
      const formattedTag = _.map(tags, tag => tag.value);
      this.onSetFilter('tagIds', formattedTag);
    } else {
      this.onSetFilter('tagIds', null);
      dispatch(UtilsActions.setViewLoading(true));
      await dispatch(GuestActions.fetchGuests());
      dispatch(UtilsActions.setViewLoading(false));
    }
  }

  closeOperationalSection() {
    const { dispatch } = this.props;
    dispatch(GuestActions.setOperationalMode(false));
    setTimeout(() => dispatch(GuestActions.selectGuest({})), 600);
  }

  openOperationalSection() {
    const { dispatch } = this.props;
    dispatch(GuestActions.setOperationalMode(true));
  }

  async selectGuest(guest) {
    const { dispatch } = this.props;
    const { guestListCredentialCategorySelected } = this.state;
    dispatch(UtilsActions.setSpinnerVisibile(true));
    await dispatch(GuestActions.selectGuest(guest));
    try {
      let newGuest = await dispatch(GuestActions.getGuestDetails(guest.id));
      newGuest = {
        ...newGuest,
        ...newGuest.customAttributes,
      };
      dispatch(GuestActions.selectGuest(newGuest));
      const initialFromDate = moment().subtract(1, 'month').valueOf();
      await dispatch(GuestActions.fetchGuestAccessMethods(newGuest));
      dispatch(GuestActions.fetchGuestCredentials(newGuest, 0, guestListCredentialCategorySelected));
      dispatch(LogEventsActions.setSmartLocksEventsFilter('fromDate', initialFromDate));
      dispatch(LogEventsActions.setSmartLocksEventsFilter('actorId', guest.id));
      dispatch(LogEventsActions.fetchSmartLocksEvents());
      dispatch(initialize('GuestProfileViewForm', newGuest));
      dispatch(LogEventsActions.setAdminEventsFilter('entityId', guest.id));
      dispatch(LogEventsActions.setAdminEventsFilter('entityType', 'USER'));
      dispatch(LogEventsActions.fetchAdminEvents());
      dispatch(GuestActions.setOperationalMode(true));
    } catch (error) {
      dispatch(GuestActions.selectGuest({}));
    } finally {
      dispatch(UtilsActions.setSpinnerVisibile(false));
    }
  }

  async onSetFilter(field, value) {
    const { dispatch } = this.props;
    if (value && ((_.isArray(value) && !_.isEmpty(value)) || (_.isString(value) && value.length >= 2))) {
      dispatch(GuestActions.setFilter(field, value));
      if (filterTimeout) clearTimeout(filterTimeout);
      filterTimeout = setTimeout(async () => {
        dispatch(UtilsActions.setViewLoading(true));
        await dispatch(GuestActions.fetchGuests());
        dispatch(UtilsActions.setViewLoading(false));
      }, 500);
    } else {
      dispatch(GuestActions.setFilter(field, null));
      await dispatch(GuestActions.fetchGuests());
    }
  }

  async onAppendGuests(page) {
    const { dispatch } = this.props;
    const { selectedTags } = this.state;
    const append = true;
    try {
      dispatch(UtilsActions.setViewLoading(true));
      await dispatch(GuestActions.fetchGuests(page, append));
      dispatch(UtilsActions.setViewLoading(false));
    } catch (error) {
      dispatch(UtilsActions.setViewLoading(false));
    }
  }


  refreshGuest() {
    const { guests: { selectedGuest } } = this.props;
    if (!_.isEmpty(selectedGuest)) this.selectGuest(selectedGuest);
  }

  async resetGuestFilters() {
    const { dispatch } = this.props;
    dispatch(UtilsActions.setViewLoading(true));
    dispatch(GuestActions.resetGuestsFilters());
    await dispatch(GuestActions.fetchGuests());
    dispatch(UtilsActions.setViewLoading(false));
  }

  onSelectGuestStatus(value) {
    let filterValue = value;
    if (value === 'ALL') filterValue = undefined;
    this.onSetFilter('enabled', filterValue);
  }

  onGuestListModeChange(activeTab) {
    const { dispatch } = this.props;
    const selectedViewMode = activeTab === 0 ? VIEW_MODES.TABLE : VIEW_MODES.CARDS;
    this.setState({ activeTab });
    GuestActions.setGuestsViewMode(selectedViewMode);
    if (activeTab === 0) {
      dispatch(GuestActions.resetGuestsPaginationData());
      dispatch(GuestActions.fetchGuests(0));
    }
  }

  onFetchGuestOnPage(page) {
    const { dispatch } = this.props;
    dispatch(GuestActions.fetchGuests(page));
  }

  onDeleteGuestRequest(guest) {
    const { dispatch } = this.props;
    if (guest.isGuestManager) {
      const params = {
        modalType: 'CONFIRM_TO_CONTINUE_MODAL',
        modalProps: {
          title: 'confirmBeforeContinue',
          message: 'userIsManagerGoToSettings',
          onConfirmText: <Entity entity="goToSection" />,
          confirmButtonStyle: { width: 180 },
          cancelButtonStyle: { width: 180 },
          onConfirm: () => {
            dispatch(ModalActions.hideModal());
            dispatch(push(`/platformUsers?userId=${guest.id}`));
          },
          onCancelText: <Entity entity="no" />,
          onCancel: () => dispatch(ModalActions.hideModal()),
        },
      };
      dispatch(ModalActions.showModal(params));
    } else {
      this.setState({ selectedGuestToDelete: guest });
      this.guestDeleteModal.open();
    }
  }


  async onDeleteGuest(guestId, oblivionSelected) {
    const { dispatch } = this.props;
    try {
      dispatch(UtilsActions.setSpinnerVisibile(true));
      await dispatch(UserActions.deleteUser(guestId, oblivionSelected));
      dispatch(GuestActions.removeGuest(guestId));
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(GuestActions.setOperationalMode(false));
      this.setState({ selectedGuestToDelete: null });
      this.guestDeleteModal.close();
      dispatch(ModalActions.showModal({
        modalType: 'SUCCESS_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="modalMessage" data={{ modal: 'userDeleted' }} /></h6>),
        },
      }));
      dispatch(GuestActions.removeGuestChached(guestId));
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="errorDeleteUserMessage" /></h6>),
        },
      }));
    }
  }

  
  onRemoveDeviceRequest(guest) {
    const { dispatch } = this.props;
    const params = {
      modalType: 'CONFIRM_TO_CONTINUE_MODAL',
      modalProps: {
        title: 'confirmBeforeContinue',
        message: 'removeDeviceConfirmation',
        onConfirmText: <Entity entity="yes" />,
        onConfirm: () => this.removeGuestMobileDevice(guest),
        onCancelText: <Entity entity="no" />,
        onCancel: () => dispatch(ModalActions.hideModal()),
      },
    };
    dispatch(ModalActions.showModal(params));
  }

  async removeGuestMobileDevice(guest) {
    const { dispatch, guests } = this.props;
    await dispatch(GuestActions.removeGuestMobileDevice(guest));
    dispatch(ModalActions.showModal({
      modalType: 'SUCCESS_ALERT',
      modalProps: {
        message: (<h6 className="snack-title"><Entity entity="modalMessage" data={{ modal: 'userDeviceResetted' }} /></h6>),
      },
    }));
    if (guests.isOperationalMode) {
      this.refreshGuest();
    }
  }


  onAllowAlwaysValidRequest(guest) {
    const { dispatch} = this.props;
    const params = {
      modalType: 'CONFIRM_TO_CONTINUE_MODAL',
      modalProps: {
        title: 'confirmBeforeContinue',
        body: <Entity entity="allowAlwaysValidUserConfirmation" />,
        onConfirmText: <Entity entity="yes" />,
        onConfirm: () => this.onAllowAlwaysValidConfirm(guest),
        onCancelText: <Entity entity="no" />,
        onCancel: () => dispatch(ModalActions.hideModal()),
      },
    };
    dispatch(ModalActions.showModal(params));
  }

  async onAllowAlwaysValidConfirm(guest) {
    const { dispatch, guests } = this.props;
    try {
      dispatch(UtilsActions.setSpinnerVisibile(true));
      await dispatch(GuestActions.onUpdateGuestMobileUuidState(guest, MOBILE_UDID_STATES.ALWAYS_VALID));
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(ModalActions.showModal({
        modalType: 'SUCCESS_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="userUpdatedSuccessfully" /></h6>),
        },
      }));
      if (guests.isOperationalMode) {
        this.refreshGuest();
      }
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="unexpectedErrorMessage" /></h6>),
        },
      }));
    }
  }

  onLimitDeviceUUIDForUser(guest) {
    const { dispatch} = this.props;
    const params = {
      modalType: 'CONFIRM_TO_CONTINUE_MODAL',
      modalProps: {
        title: 'confirmBeforeContinue',
        body: <Entity entity="limitDeviceForValidUserConfirmation" />,
        onConfirmText: <Entity entity="yes" />,
        onConfirm: () => this.onLimitDeviceUUIDForUserConfirm(guest),
        onCancelText: <Entity entity="no" />,
        onCancel: () => dispatch(ModalActions.hideModal()),
      },
    };
    dispatch(ModalActions.showModal(params));
  }

  async onLimitDeviceUUIDForUserConfirm(guest) {
    const { dispatch, guests } = this.props;
    try {
      dispatch(UtilsActions.setSpinnerVisibile(true));
      await dispatch(GuestActions.onUpdateGuestMobileUuidState(guest, MOBILE_UDID_STATES.NEVER_SET));
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(ModalActions.showModal({
        modalType: 'SUCCESS_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="userUpdatedSuccessfully" /></h6>),
        },
      }));
      if (guests.isOperationalMode) {
        this.refreshGuest();
      }
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="unexpectedErrorMessage" /></h6>),
        },
      }));
    }
  }

  onDisableGuestRequest(guest) {
    const { dispatch } = this.props;
    const params = {
      modalType: 'CONFIRM_TO_CONTINUE_MODAL',
      modalProps: {
        title: 'confirmBeforeContinue',
        message: 'disableGuestConfirmation',
        onConfirmText: <Entity entity="yes" />,
        onConfirm: () => this.disableGuest(guest),
        onCancelText: <Entity entity="no" />,
        onCancel: () => dispatch(ModalActions.hideModal()),
      },
    };
    dispatch(ModalActions.showModal(params));
  }

  async disableGuest(guest) {
    const { dispatch, guests } = this.props;
    dispatch(UtilsActions.setSpinnerVisibile(true));
    try {
      await dispatch(GuestActions.disableGuest(guest));
      dispatch(ModalActions.showModal({
        modalType: 'SUCCESS_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="modalMessage" data={{ modal: 'userDisabled' }} /></h6>),
        },
      }));
      if (guests.isOperationalMode) {
        this.refreshGuest();
      }
    } finally {
      dispatch(UtilsActions.setSpinnerVisibile(false));
    }
  }

  async onResetGuestPassword(guestId) {
    const { dispatch } = this.props;
    try {
      dispatch(UtilsActions.setSpinnerVisibile(true));
      await dispatch(UserActions.resetPassword(guestId));
      dispatch(UtilsActions.setSpinnerVisibile(false));
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisibile(false));

    }
  }

  onExportElements(format) {
    const { dispatch } = this.props;
    if (format === EXPORT_FORMATS.CSV) {
      dispatch(GuestActions.exportGuestCsv());
    } else {
      dispatch(GuestActions.exportGuestsXLSX());
    }
  }
  
  async onRefreshGuests() {
    const { guests } = this.props;
    const page = guests && guests.data && guests.data.pagination && guests.data.pagination.number;
    this.onFetchGuestOnPage(page);
  }
  
  onOrderChanged() {
    const { dispatch } = this.props;
    dispatch(GuestActions.fetchGuests())
  }

  onSelectedUserCredentialListType(credentialCategory) {
    this.setState({ guestListCredentialCategorySelected: credentialCategory });
  }
  

  async onImportGuestsCSVConfirmed(users) {
    const { dispatch } = this.props;
    try {
      dispatch(UtilsActions.setSpinnerVisibile(true));
      const usersWithErrors = await dispatch(GuestActions.generateCSVFromUsersListAndImport(users));
      if (!_.isEmpty(usersWithErrors)) {
        const params = {
          modalType: 'CONTENT_MODAL',
          modalProps: {
            content: (<GuestAddCSVMessage usersWithErrors={usersWithErrors} />),
            onConfirmText: <Entity entity="gotcha" />,
            modalStyle: { height: '90% !important' },
            onOutsideClick: () => dispatch(ModalActions.hideModal()),
            onConfirm: () => dispatch(ModalActions.hideModal()),
          },
        };
        dispatch(ModalActions.showModal(params));
      } else {
        dispatch(ModalActions.showModal({
          modalType: 'SUCCESS_ALERT',
          modalProps: {
            message: (<h6 className="snack-title"><Entity entity="importGuestCSVSuccess" /></h6>),
          },
        }));
      }
      this.setState({ showImportCSVOperationalSection: false });
      dispatch(GuestActions.fetchGuests());
      dispatch(UtilsActions.setSpinnerVisibile(false));
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="errorImportingCSV" /></h6>),
        },
      }));
    }
  }

  async onOpenImportCSVView() {
    const { dispatch } = this.props;
    try {
      dispatch(UtilsActions.setSpinnerVisibile(true));
      await dispatch(TagsActions.fetchAllGuestSpecialTags());
      dispatch(UtilsActions.setSpinnerVisibile(false));
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisibile(false));
    }
    dispatch(initialize('ImportGuestsCSVForm', { guestTagMatchingMode: MATCH_TAG_MODE.AT_LEAST_ONE_TAG }));
    this.setState({ showImportCSVOperationalSection: true });
  }

  onAddTagsBatchRequest(guests) {
    const { dispatch } = this.props;
    const params = {
      modalType: 'CONTENT_MODAL',
      modalProps: {
        content: (<GuestsTagsBatchView removeMode={false} guests={guests} onAddTagsGuestsBatchConfirm={(tags) => {this.onAddTagsGuestsBatchConfirm(guests, tags)}} />),
        modalStyle: { height: '90% !important' },
        onOutsideClick: () => dispatch(ModalActions.hideModal()),
      },
    };
    dispatch(ModalActions.showModal(params));
  }

  onRemoveTagsBatchRequest(guests) {
    const { dispatch } = this.props;
    const tags = []
    guests.forEach(guest => {
      guest.tags.forEach(tag => {
        tags.push(tag)
      })
    })
    const removableTags = _.uniqBy(tags, 'id')
    dispatch(initialize('GuestBatchTagsForm', { removableTags, deletingTags: [] }));
    const params = {
      modalType: 'CONTENT_MODAL',
      modalProps: {
        content: (<GuestsTagsBatchView removeMode={true} guests={guests} onRemoveTagsGuestsBatchConfirm={(tags) => {this.onRemoveTagsGuestsBatchConfirm(guests, tags)}} />),
        modalStyle: { height: '90% !important' },
        onOutsideClick: () => dispatch(ModalActions.hideModal()),
      },
    };
    dispatch(ModalActions.showModal(params));
  }

  async onAddTagsGuestsBatchConfirm(guests, tags) {
    const { dispatch, guests: { data : { pagination: { number }} } } = this.props;
    const uniqueGuests = _.uniqBy(guests, 'id')
    try {
      dispatch(UtilsActions.setSpinnerVisibile(true));
      await dispatch(GuestActions.addTagsBatchUsers(uniqueGuests, tags));
      dispatch(ModalActions.hideModal());
      dispatch(GuestActions.fetchGuests(number));
      dispatch(UtilsActions.setSpinnerVisibile(false));
    } catch (error) {
      dispatch(ModalActions.hideModal());
      dispatch(UtilsActions.setSpinnerVisibile(false));
      const params = {
        modalType: 'CONTENT_MODAL',
        modalProps: {
          content: (<GuestsTagsBatchError guests={uniqueGuests} onClose={() => dispatch(ModalActions.hideModal())} />),
          modalStyle: { height: '90% !important' },
          onOutsideClick: () => dispatch(ModalActions.hideModal()),
        },
      };
      dispatch(ModalActions.showModal(params));
    }
  }

  async onRemoveTagsGuestsBatchConfirm(guests, tags) {
    const { dispatch, guests: { data : { pagination: { number }} } } = this.props;
    const uniqueGuests = _.uniqBy(guests, 'id')
    try {
      dispatch(UtilsActions.setSpinnerVisibile(true));
      await dispatch(GuestActions.removeTagsBatchUsers(uniqueGuests, tags));
      dispatch(ModalActions.hideModal());
      dispatch(GuestActions.fetchGuests(number));
      dispatch(UtilsActions.setSpinnerVisibile(false));
    } catch (error) {
      dispatch(ModalActions.hideModal());
      dispatch(UtilsActions.setSpinnerVisibile(false));
      const params = {
        modalType: 'CONTENT_MODAL',
        modalProps: {
          content: (<GuestsTagsBatchError guests={uniqueGuests} onClose={() => dispatch(ModalActions.hideModal())} />),
          modalStyle: { height: '90% !important' },
          onOutsideClick: () => dispatch(ModalActions.hideModal()),
        },
      };
      dispatch(ModalActions.showModal(params));
    }
  }

  onDeleteGuestBatchRequest(guests) {
    const { dispatch } = this.props;
    const params = {
      modalType: 'CONTENT_MODAL',
      modalProps: {
        content: (<GuestsDeleteBatchView guests={guests} onDeleteGuestsBatchConfirm={(oblivionRequested) => this.onDeleteGuestBatchConfirm(guests, oblivionRequested)} />),
        modalStyle: { height: '90% !important' },
        onOutsideClick: () => dispatch(ModalActions.hideModal()),
      },
    };
    dispatch(ModalActions.showModal(params));
  }

  async onDeleteGuestBatchConfirm(guests, oblivionRequested) {
    const { dispatch } = this.props;
    try {
      dispatch(UtilsActions.setSpinnerVisibile(true));
      await dispatch(GuestActions.deleteBatchUsers(guests, oblivionRequested));
      dispatch(ModalActions.hideModal());
      dispatch(GuestActions.fetchGuests());
      dispatch(UtilsActions.setSpinnerVisibile(false));
    } catch (error) {
      dispatch(ModalActions.hideModal());
      dispatch(UtilsActions.setSpinnerVisibile(false));
      const params = {
        modalType: 'CONTENT_MODAL',
        modalProps: {
          content: (<GuestsDeleteBatchError guests={guests} onClose={() => dispatch(ModalActions.hideModal())} />),
          modalStyle: { height: '90% !important' },
          onOutsideClick: () => dispatch(ModalActions.hideModal()),
        },
      };
      dispatch(ModalActions.showModal(params));
    }
  }

  onDisableGuestsBatch(guests) {
    const { dispatch } = this.props;
    const params = {
      modalType: 'CONTENT_MODAL',
      modalProps: {
        content: (<GuestEnableDisableBatchView isDisabling guests={guests} onDisableGuestsBatchConfirm={() => this.onDisableGuestsBatchConfirm(guests)} />),
        modalStyle: { height: '90% !important' },
        onOutsideClick: () => dispatch(ModalActions.hideModal()),
      },
    };
    dispatch(ModalActions.showModal(params));
  }

  async onDisableGuestsBatchConfirm(guests) {
    const { dispatch, guests: { data : { pagination: { number }} } } = this.props;
    try {
      dispatch(UtilsActions.setSpinnerVisibile(true));
      await dispatch(GuestActions.disableBatchUsers(guests));
      dispatch(ModalActions.hideModal());
      dispatch(GuestActions.fetchGuests(number));
      dispatch(UtilsActions.setSpinnerVisibile(false));
    } catch (error) {
      dispatch(ModalActions.hideModal());
      dispatch(UtilsActions.setSpinnerVisibile(false));
      const params = {
        modalType: 'CONTENT_MODAL',
        modalProps: {
          content: (<GuestEnableDisableBatchError isDisabling guests={guests} onClose={() => dispatch(ModalActions.hideModal())} />),
          modalStyle: { height: '90% !important' },
          onOutsideClick: () => dispatch(ModalActions.hideModal()),
        },
      };
      dispatch(ModalActions.showModal(params));
    }
  }

  onEnableGuestsBatch(guests) {
    const { dispatch } = this.props;
    const params = {
      modalType: 'CONTENT_MODAL',
      modalProps: {
        content: (<GuestEnableDisableBatchView guests={guests} onEnableGuestsBatchConfirm={() => this.onEnableGuestsBatchConfirm(guests)} />),
        modalStyle: { height: '90% !important' },
        onOutsideClick: () => dispatch(ModalActions.hideModal()),
      },
    };
    dispatch(ModalActions.showModal(params));
  }

  async onEnableGuestsBatchConfirm(guests) {
    const { dispatch, guests: { data : { pagination: { number }} } } = this.props;
    try {
      dispatch(UtilsActions.setSpinnerVisibile(true));
      await dispatch(GuestActions.enableBatchUsers(guests));
      dispatch(ModalActions.hideModal());
      dispatch(GuestActions.fetchGuests(number));
      dispatch(UtilsActions.setSpinnerVisibile(false));
    } catch (error) {
      dispatch(ModalActions.hideModal());
      dispatch(UtilsActions.setSpinnerVisibile(false));
      const params = {
        modalType: 'CONTENT_MODAL',
        modalProps: {
          content: (<GuestEnableDisableBatchError guests={guests} onClose={() => dispatch(ModalActions.hideModal())} />),
          modalStyle: { height: '90% !important' },
          onOutsideClick: () => dispatch(ModalActions.hideModal()),
        },
      };
      dispatch(ModalActions.showModal(params));
    }
  }


  async onCustomFieldSearch(value, customFieldKey) {
    const { dispatch } = this.props;
    const customAttributesTerm = value;
    const customAttributesFields = [customFieldKey];
    dispatch(GuestActions.setFilter('customAttributesTerm', customAttributesTerm));
    dispatch(GuestActions.setFilter('customAttributesFields', customAttributesFields));
    setTimeout(async () => await dispatch(GuestActions.fetchGuests()), 300);
  }

  async onSelectProfileSearch(profile) {
    const { dispatch } = this.props;
    if (profile && profile.value) {
      dispatch(GuestActions.setFilter('accessProfileId', profile.value));
    } else {
      dispatch(GuestActions.setFilter('accessProfileId', null));
    }
    await dispatch(GuestActions.fetchGuests());
  }

  onSetGuestTagsVisibility(guest, isVisible) {
    const { dispatch } = this.props;
    dispatch(GuestActions.updateGuestOnState({...guest, showAllTags: isVisible }))
  }


  render() {
    const { guests, dispatch, themeName, viewLoading, customFields } = this.props;
    const { showImportCSVOperationalSection } = this.state;
    const { activeTab, selectedGuestToDelete, guestListCredentialCategorySelected } = this.state;
    const canCreateGuest = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.CREATE], PERMISSION_ENTITIES.USER);
    const numberOfGuests = guests && guests.data && guests.data.pagination && guests.data.pagination.totalElements ? guests.data.pagination.totalElements : 0;
    const guestsData = guests && guests.data && guests.data.content;
    const isLuckeyLite = AbilityProvider.getAbilityHelper().hasLicenseType([LICENSE_TYPES.LITE]);
    const isAccessProfileAddonActive = dispatch(UtilsActions.isAccessProfileAddonActive());
    return (
      <div style={{ backgroundColor: 'white'}}>
        <PresentationalViewHeader
          themeName={themeName}
          onNewEntity={canCreateGuest ? () => this.onNewUserButtonClicked() : null}
          newEntityTitle="addGuest"
          searchPlaceholderEntityName="searchGuestNameEmail"
          searchNameContainerStyle={{ width: 220 }}
          isGuestTagsMultiSelectionActive
          newEntityIconName="icon-simple-line-icons-user-follow"
          isLoading={viewLoading}
          onSelectGuestTag={tag => this.filterGuestByTag(tag)}
          onSelectProfile={isAccessProfileAddonActive ? (profile) => this.onSelectProfileSearch(profile) : null}
          onFilterChange={value => this.onSetFilter('search', _.trim(value))}
          onSearchReset={() => this.resetGuestFilters()}
          onGuestCompanyNameChange={text => this.onSetFilter('companyName', _.trim(text))}
          onSelectOption={value => this.onSelectGuestStatus(value)}
          customFields={customFields}
          onCustomFieldSearch={(value, customFieldKey) => this.onCustomFieldSearch(value, customFieldKey)}
          selectableFieldName="status"
          showEntitiesNumber
          onExportElements={!isLuckeyLite ? format => this.onExportElements(format) : null}
          onImportFromCSV={canCreateGuest && !isLuckeyLite ? () => this.onOpenImportCSVView() : null}
          numberOfElements={numberOfGuests}
          entitiesNumberSection={
            <div style={{ marginRight: 20, marginTop: 13, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <UsersIconCustom style={{ width: 25 }} />
              <h4 style={{ marginTop: 0 }}>{numberOfGuests}</h4>
            </div>
          }
          selectableOptions={_.map(statusFilterOption, status =>
            <Entity
              key={status.name}
              componentClass={TranslatableOption}
              value={status.value}
              componentAttribute="text"
              entity={status.label}
            />)}
        />
        {guestsData && !_.isEmpty(guestsData) && !isLuckeyLite ? (
          <div style={{ top: 140, position: 'fixed', backgroundColor: 'white' }}>
            <Tabs
              value={activeTab}
              indicatorColor="primary"
              textColor="primary"
              onChange={(e, index) => this.onGuestListModeChange(index)}
            >
              <Tab icon={<ListViewIcon style={{ fontSize: 30 }} />} />
              <Tab icon={<CardViewIcon style={{ fontSize: 30 }} />} />
            </Tabs>
          </div>
        ) : null}
        {activeTab === 0 && !isLuckeyLite ? (
          <GuestTableView
            onSelectGuest={guest => this.selectGuest(guest)}
            onFetchGuestOnPage={page => this.onFetchGuestOnPage(page)}
            onDeleteGuestRequest={guest => this.onDeleteGuestRequest(guest)}
            onRemoveDeviceRequest={guest => this.onRemoveDeviceRequest(guest)}
            onDisableGuestRequest={guest => this.onDisableGuestRequest(guest)}
            onResetGuestPassword={guest => this.onResetGuestPassword(guest.id)}
            onRefreshGuests={() => this.onRefreshGuests()}
            onOrderChanged={() => this.onOrderChanged()}
            onNewGuest={() => this.onNewUserButtonClicked()}
            isAccessProfileAddonActive={isAccessProfileAddonActive}
            onDeleteGuestBatch={guests => this.onDeleteGuestBatchRequest(guests)}
            onAddTagsBatch={guests => this.onAddTagsBatchRequest(guests)}
            onRemoveTagsBatch={guests => this.onRemoveTagsBatchRequest(guests)}
            onDisableGuestsBatch={guests => this.onDisableGuestsBatch(guests)}
            onSetGuestTagsVisibility={(guest, isVisible) => this.onSetGuestTagsVisibility(guest, isVisible)}
          />
        ) : null}
        {activeTab === 1 || isLuckeyLite ? (
          <GuestsListView
            themeName={themeName}
            containerStyle={{ top: isLuckeyLite ? 140 : 190 }}
            onSelectGuest={guest => this.selectGuest(guest)}
            onNewGuest={() => this.onNewUserButtonClicked()}
            isAccessProfileAddonActive={isAccessProfileAddonActive}
            onAppendGuests={page => this.onAppendGuests(page)}
          />
        ) : null}
        <OperationalView
          themeName={themeName}
          isVisible={guests.isOperationalMode}
          onClose={() => this.closeOperationalSection()}
          style={{ margin: 0, padding: 0 }}
          title={<Entity entity="sectionTitle" data={{ name: 'guestsOperational' }} />}
        >
          <GuestsOperationalSection
            themeName={themeName}
            ref={(c) => { this.guestsOperationalSection = c; }}
            onGuestFormSubmit={() => dispatch(submit('GuestForm'))}
            guestListCredentialCategorySelected={guestListCredentialCategorySelected}
            onDeleteGuestRequest={guest => this.onDeleteGuestRequest(guest)}
            onLimitDeviceUUIDForUser={guest => this.onLimitDeviceUUIDForUser(guest)}
            onRemoveDeviceRequest={guest => this.onRemoveDeviceRequest(guest)}
            onAllowAlwaysValid={guest => this.onAllowAlwaysValidRequest(guest)}
            onDisableGuestRequest={guest => this.onDisableGuestRequest(guest)}
            onResetGuestPassword={guestId => this.onResetGuestPassword(guestId)}
            onSelectedUserCredentialListType={credentialType => this.onSelectedUserCredentialListType(credentialType)}
          />
        </OperationalView>
        <BasicModal
          ref={(m) => { this.guestDeleteModal = m; }}
          hideCloseButton
          body={
            <div>
              <GuestDeleteView
                guest={selectedGuestToDelete}
                onCloseModal={() => this.guestDeleteModal.close()}
                onDeleteGuestConfirm={(oblivionSelected) => this.onDeleteGuest(selectedGuestToDelete.id, oblivionSelected)}
              />
            </div>
          }
        />
        <OperationalView
          themeName={themeName}
          isVisible={showImportCSVOperationalSection}
          onClose={() => this.setState({ showImportCSVOperationalSection: false })}
          style={{ margin: 0, padding: 0 }}
          title={<Entity entity="sectionTitle" data={{ name: 'importGuestsFromCSV' }} />}
        >
          <ImportGuestsCSV
            onImportGuests={(users) => this.onImportGuestsCSVConfirmed(users)}
          />
        </OperationalView>
      </div>
    );
  }
} 

export default Guests;
