import React from 'react';
import WarningIcon from '@material-ui/icons/Warning';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import moment from 'moment';
import UserRemoveIconCustom from '../../CustomIcons/UserRemoveIconCustom.jsx';


export default class UserDeleteEventRow extends React.Component {
  render() {
    const { event: { id, timestamp, actorId, data }, isAnonymized } = this.props;
    const actor = data && data.actor ? data.actor : null;
    return (
      <div style={{ minHeight: 110, padding: 15, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <UserRemoveIconCustom style={{ width: 30, marginRight: 30, color: '#3f3f3f' }} />
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <h3 style={{ color: '#3f3f3f', fontWeight: 'bold', margin: 0 }}>
              <Entity key={id} entity="userDeleted" />
            </h3>
            <h4 style={{ color: '#3f3f3f', lineHeight: '1.5em', fontWeight: '100', margin: 0, marginTop: 10 }}>
              {isAnonymized ? (
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <WarningIcon style={{ color: 'orange', marginRight: 10, fontSize: 20 }} />
                  <h4 style={{ color: '#3f3f3f', lineHeight: '1.5em', fontWeight: '100', margin: 0 }}>
                    <Entity
                      key={id}
                      entity="adminEventRowAnonymizedDescription"
                    />
                  </h4>
                </div>
              ) : (
                <Entity
                  key={id}
                  entity="userDeleteDescription"
                  data={{
                    actor: actor && actorId ? `${actor.firstname} ${actor.lastname || ''} ${actor.email || ''}` : '---',
                  }}
                />
            )}
            </h4>
          </div>
        </div>
        <h4 style={{ minWidth: 240, textAlign: 'right', marginTop: 10, color: '#3f3f3f' }}>{moment(timestamp).format('LLL')}</h4>
      </div>
    );
  }
}