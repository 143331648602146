// @ts-nocheck
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { change, submit } from 'redux-form';
import { BLUE } from '../../_config/consts';
import * as ModalActions from '../../redux/actions/modal.actions';
import TicketForm from '../forms/TicketForm.jsx';
import MDButton from '../MDButton/MDButton.jsx';
import TicketDetailsView from './TicketDetailsView.jsx';

const theme = createTheme({
  palette: {
    primary: { 500: BLUE },
  },
});
@connect(state => ({
  form: state.form.TicketForm,
  ticket: state.tickets.selectedTicket,
  language: state.settings.language,
  themeName: state.settings.items.theme.data.themeName,
}))
class TicketsOperationalSection extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      
    };
  }

  componentWillMount() {
    const { dispatch } = this.props;
  }

  onFileRejected() {
    const { dispatch } = this.props;
    dispatch(ModalActions.showModal({
      modalType: 'ERROR_MODAL',
      modalProps: { type: 'FILE_TOO_LARGE' },
    }));
  }

  onDeleteFile(fileIndex) {
    const { dispatch } = this.props;
    const { form, } = this.props;
    dispatch(change('TicketForm', 'image', form.values.image.filter((item, i) => i !== fileIndex)));
  }

  render() {
    const {
      dispatch,
      ticket,
      themeName,
      onTicketEditConfirmed,
      onTicketCreateConfirmed,
      onNewMessageRequest,
      onCloseTicketRequest,
      onResolveTicketRequest,
      onOpenTicketRequest,
      prioritiesArray,
      categoriesArray,
      statusArray,
      form,
    } = this.props;

    let imagePreviews = form && form.values && form.values.image;
    if (imagePreviews && _.isArray(imagePreviews))
      imagePreviews = imagePreviews.map(e=>{return e.preview})
    else
      imagePreviews = []


    return (
      <MuiThemeProvider theme={theme}>
        <div>
          {!ticket || _.isEmpty(ticket) ? (
            <div style={{ padding: 20, paddingBottom: '30%' }}>
              <TicketForm
                onSelectReferentUser={null}
                categoriesArray={categoriesArray}
                onDeleteFile={(fileIndex) => this.onDeleteFile(fileIndex)}
                onFileRejected={() => this.onFileRejected()}
                imagePreviews={imagePreviews}
                onSubmit={ticketData => onTicketCreateConfirmed(ticketData)}
              />
              <MDButton
                title={<Entity entity="confirm" />}
                containerStyle={{ position: 'absolute', left: 0, right: 0, bottom: 0, width: '100%', margin: 0 }}
                style={{ height: 45, borderRadius: 0 }}
                onClick={() => dispatch(submit('TicketForm'))}
              />
            </div>
          ) : (
            <div>
              <TicketDetailsView
                ticket={ticket}
                onSubmit={(ticketData)=>onTicketEditConfirmed(ticket.id,ticketData)}
                onNewMessageRequest={(newMessage,attachments)=>onNewMessageRequest(ticket.id,newMessage,attachments)}
                onCloseTicketRequest={()=>onCloseTicketRequest(ticket.id)}
                onResolveTicketRequest={()=>onResolveTicketRequest(ticket.id)}
                onOpenTicketRequest={()=>onOpenTicketRequest(ticket.id)}
                prioritiesArray={prioritiesArray}
                categoriesArray={categoriesArray}
                statusArray={statusArray}
              />
            </div>
          )}
        </div>
      </MuiThemeProvider>
    );
  }
} 

export default TicketsOperationalSection;
