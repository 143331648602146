import { resourceActions as ResourceActions } from '@bottega52/bookey-redux-module';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import React from 'react';
import ListViewIcon from '@material-ui/icons/ViewList';
import CardViewIcon from '@material-ui/icons/ViewStream';
import { connect } from 'react-redux';
import { change, initialize, reset, submit } from 'redux-form';
import OperationalView from '../../components/OperationalView/OperationalView.jsx';
import PresentationalViewHeader from '../../components/PresentationalView/PresentationalViewHeader.jsx';
import ResourceList from '../../components/Resources/ResourceList.jsx';
import ResourcesOperationalSection from '../../components/Resources/ResourcesOperationalSection.jsx';
import * as CredentialActions from '../../redux/actions/credential.actions';
import * as AccessoriesActions from '../../redux/actions/accessories.actions';
import * as LocksActions from '../../redux/actions/lock.actions';
import * as ModalActions from '../../redux/actions/modal.actions';
import * as TagsActions from '../../redux/actions/tag.actions';
import * as UtilsActions from '../../redux/actions/utils.actions';
import * as ResourceTypesEpic from '../../epics/resourcesTypes.epics';
import { LICENSE_TYPES, MATCH_TAG_MODE, VIEW_MODES } from '../../_config/consts';
import * as formatter from '../../_config/formatter';
import { localizeHelpCenterLink, saveDataToLocalStorage } from '../../_config/utils.js';
import TranslatableOption from '../../components/forms/Fields/TranslatableOption.jsx';
import ResourceSharingForm from '../../components/forms/Bookey/ResourceSharingForm.jsx';
import ResourcesTableView from '../../components/Resources/ResourcesTableView.jsx';
import AbilityProvider from '../../permissionsUtils/AbilityProvider.js';

let filterTimeout;
@connect((state) => ({ resources: state.resources, resourcesTypes: state.resourceTypes.data.content, resource: state.resources.selectedResource, subcompanies: state.settings.subcompanies, accessories: state.accessories.data, themeName: state.settings.items.theme.data.themeName, userCachedTags: state.tags.user.cachedTagsMap, lockCachedTags: state.tags.lock.cachedTagsMap, }))
class Resources extends React.Component {
  constructor(props) {
    const cachedViewMode = localStorage.getItem('resourcesViewMode');
    super(props);
    this.state = {
      isOperationalMode: false,
      viewLoading: false,
      showResourceSharingSection: false,
      activeTab: cachedViewMode && cachedViewMode === VIEW_MODES.CARDS ? 1 : 0,
    };
  }
  
  async componentWillMount() {
    const { dispatch } = this.props;
    dispatch(ResourceActions.setSelectedResource({}));
    dispatch(ResourceActions.fetchResourceUnavailabilities());
    this.fetchResourceTypes()
    
    this.setState({ viewLoading: true });
    try {
      dispatch(ResourceActions.resetResourcesFilters());
      await this.fetchResourcesAndTags();
      this.setState({ viewLoading: false });
    } catch (error) {
      this.setState({ viewLoading: false });
    }
    dispatch(AccessoriesActions.fetchAccessories());
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(ResourceActions.setSelectedResource({}));
  }

  async fetchResourcesAndTags(page = 0, filters = {}, append = false) {
    const { dispatch } = this.props;
    try {
      const resources = await dispatch(ResourceActions.fetchResourcesWithMedia(page, 20, filters, append));
      _.each(resources, resource => dispatch(LocksActions.cacheLockDetailsIfNeeded(resource.checkInSmartLockId)));
      dispatch(ResourceActions.fetchResourcesWorkspaces(0, 50));
      this.setState({ viewLoading: false });
    } catch (error) {
      this.setState({ viewLoading: false });
    }
  }

  fetchResourceTypes() {
    const { dispatch } = this.props;
    dispatch(ResourceTypesEpic.fetchResourcesTypesByFilter(undefined, 50));
  }


  onNewResourceButtonClicked() {
    const { dispatch } = this.props;
    dispatch(reset('ResourcesForm'));
    dispatch(ResourceActions.setSelectedResource({}));
    dispatch(CredentialActions.selectCredential({}));
    dispatch(AccessoriesActions.fetchAccessories());
    const resourceInit = formatter.formatOutputData(formatter.RESOURCE_FORM_INIT);
    dispatch(initialize('ResourcesForm', resourceInit));
    this.onOpenOperationalSection();
  }

  async onResourceSelected(resource) {
    const { dispatch } = this.props;
    try {
      dispatch(UtilsActions.setSpinnerVisibile(true));
      let detailedResource = {
        ...resource,
      };
      dispatch(ResourceActions.setSelectedResource(detailedResource));
      try {
        const resourceDependency = await dispatch(ResourceActions.fetchResourceDependencies(detailedResource.id, 0, 20, false));
        detailedResource = {
          ...detailedResource,
          dependencies: resourceDependency,
        }
      } catch (error) {}

      //Handle accessories
      try {
        const resourceAccessories = await dispatch(AccessoriesActions.fetchResourceAccessories(detailedResource.id));
        detailedResource = {
          ...detailedResource,
          resourceAccessories,
        }
      } catch (error) {
        dispatch(AccessoriesActions.resetAccessoriesFilters());
      }
      // These two function allow to retrieve the actual locks and guests selected by the tags
      try {
        await dispatch(CredentialActions.fetchLocksByTags(resource.resourceSmartLockTags, resource.lockTagMatchingMode));
      } catch (error) {}
      try {
        await dispatch(CredentialActions.fetchGuestsByTags(resource.resourceUserTags, resource.userTagMatchingMode));
      } catch (error) {}

       //Handle resource user tags
      if (resource.resourceUserTags && !_.isEmpty(resource.resourceUserTags)) {
        try {
          const resourceUserTagsDetailed = await dispatch(TagsActions.fetchGuestTagsByIds(resource.resourceUserTags));
          detailedResource = {
            ...detailedResource,
            resourceUserTags: resourceUserTagsDetailed,
          }
        } catch (error) {
        }
      }
      //Handle resource lock tags
      if (resource.resourceSmartLockTags && !_.isEmpty(resource.resourceSmartLockTags)) {
        try {
          const resourceLockTagsDetailed = await dispatch(TagsActions.fetchLockTagsByIds(resource.resourceSmartLockTags));
          detailedResource = {
            ...detailedResource,
            resourceSmartLockTags: resourceLockTagsDetailed,
          }
        } catch (error) {
        }
      }
      if (resource.checkInSmartLockId) await dispatch(LocksActions.cacheLockDetailsIfNeeded(resource.checkInSmartLockId));

      if (resource.shared) {
        const subcompanies = await dispatch(ResourceActions.fetchSharedResources(0, 100, { sharedFromResourceId: resource.id }, false));
        detailedResource = {
          ...detailedResource,
          subcompanies,
        }
      }
      dispatch(initialize('ResourcesForm', formatter.formatOutputData(formatter.RESOURCE_FORM_EDIT_INIT, detailedResource)));
      dispatch(AccessoriesActions.fetchAccessories());
      this.onOpenOperationalSection();
    } catch (error) {
      dispatch(ResourceActions.setSelectedResource({}));
      dispatch(UtilsActions.setSpinnerVisibile(false));
    } finally {
      dispatch(UtilsActions.setSpinnerVisibile(false));
    }
  }

  onCloseOperationalSection() {
    const { dispatch } = this.props;
    this.setState({ isOperationalMode: false });
    this.fetchResourceTypes();
    dispatch(ResourceActions.setSelectedResource({}));
  }

  onOpenOperationalSection() {
    this.setState({ isOperationalMode: true });
  }
  onNewRource() {
    this.setState({ isOperationalMode: true });
  }

  onDeleteResource(resource) {
    const { dispatch } = this.props;
    const warningMessage = resource && resource.shared ?  'deleteResourceSharedConfirmation' : 'deleteResourceConfirmation';
    const params = {
      modalType: 'CONFIRM_TO_CONTINUE_MODAL',
      modalProps: {
        title: 'confirmBeforeContinue',
        body: <Entity entity={warningMessage} />,
        onConfirmText: <Entity entity="yes" />,
        onConfirm: () => this.onDeleteResourceConfirm(resource.id),
        onCancelText: <Entity entity="no" />,
        onCancel: () => dispatch(ModalActions.hideModal()),
      },
    };
    dispatch(ModalActions.showModal(params));
  }

  async onDeleteResourceConfirm(resourceId) {
    const { dispatch } = this.props;
    dispatch(UtilsActions.setSpinnerVisibile(true));
    try {
      await dispatch(ResourceActions.deleteResource(resourceId));
      dispatch(ModalActions.hideModal());
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_MODAL',
        modalProps: { type: 'DEFAULT_ERROR', defaultMessage: <Entity entity="errorDeletingResource" /> },
      }));
    } finally {
      this.onCloseOperationalSection();
      dispatch(UtilsActions.setSpinnerVisibile(false));
    }
  }

  async onHandleDependencies(resource, dependencyTargetIdsToAdd, dependencyTargetIdsToRemove) {
    try {
      const { dispatch, resources: { resourceDependencies: { content: resourceDependenciesData } } } = this.props;
      const dependenciesToRemove = _.filter(resourceDependenciesData, dependency => _.indexOf(dependencyTargetIdsToRemove, dependency.destination.id) !== -1);
      const dependenciesToAdd = _.map(dependencyTargetIdsToAdd, destinationId => ({
        destinationId,
        type: 'BLOCK',
      }));
      try {
        _.each(dependenciesToRemove, dependencyToRemove => (
          dispatch(ResourceActions.deleteResourceDependency(resource.id, dependencyToRemove.id))
        ));
      } catch (error) {}

      try {
        _.each(dependenciesToAdd, dependency => (
          dispatch(ResourceActions.createResourceDependency(resource.id, dependency))
        ));
      } catch (error) {}
    } catch (error) {
    }
  }

  async onHandleResourceAccessories(resource, accessoriesIds, oldAccessories) {
    try {
      const { dispatch, accessories: { content: acessoriesData }, resources: { resourceDependencies: { content: resourceDependenciesData } } } = this.props;
      let accessoryToUpdate = _.filter(acessoriesData, accessory => _.indexOf(accessoriesIds, accessory.id) !== -1);
      let accessoriesToRemove = _.filter(oldAccessories, accessory => _.indexOf(accessoriesIds, accessory.id) === -1);
      // Remove accessories unselected
      accessoriesToRemove = _.map(accessoriesToRemove, accessory => ({
        ...accessory,
        entityId: 0,
        action: 'NONE',
      }));
      try {
        _.each(accessoriesToRemove, accessory => (
          dispatch(AccessoriesActions.updateAccessory(accessory))
        ));
      } catch (error) {}
      // Add new accessories
      accessoryToUpdate = _.map(accessoryToUpdate, accessory => ({
        ...accessory,
        action: 'BOOKEY_CHECKIN',
        entityId: resource.id,
      }));

      try {
        _.each(accessoryToUpdate, accessory => (
          dispatch(AccessoriesActions.updateAccessory(accessory))
        ));
      } catch (error) {}
    } catch (error) {
    }
  }

  async createResource(resource, isCustomMedia, media) {
    const { dispatch } = this.props;
    let createdResource = await dispatch(ResourceActions.createResource(resource));
    if (media && isCustomMedia) {
      try {
        createdResource = await dispatch(ResourceActions.createResourceMedia(createdResource.id, media));
        const newMedia = await dispatch(ResourceActions.fetchResourceMedia(createdResource.id));
        createdResource = {
          ...createdResource,
          media: newMedia,
        }
      } catch (error) {
        // TODO: handle error in photo upload
      }
    }
    return createdResource;
  }

  async editResource(resource, isCustomMedia, media) {
    const { dispatch } = this.props;
    let createdResource = await dispatch(ResourceActions.editResource(resource));
    if (media && media instanceof File && isCustomMedia) {
      try {
        await dispatch(ResourceActions.createResourceMedia(createdResource.id, media));
        const newMedia = await dispatch(ResourceActions.fetchResourceMedia(createdResource.id));
        createdResource = {
          ...createdResource,
          media: newMedia,
        }
      } catch (error) {
        // TODO: handle error in photo upload
      }
    } else if (media && _.isString(media) && isCustomMedia) {
      createdResource = {
        ...createdResource,
        media,
      }
      // if we are editing a resource with a stock image and previusly it was a custom image, we need to delete the custom image
    } else if (media && _.isString(media) && resource.customMedia && !isCustomMedia) { 
      await dispatch(ResourceActions.deleteResourceMedia(createdResource.id));
    }
    return createdResource;
  }

  async onSaveResource(resourceValues) {
    const { dispatch, resources: { resourceDependencies } } = this.props;
    dispatch(UtilsActions.setSpinnerVisibile(true));
    const resourceFormatted = formatter.formatOutputData(formatter.RESOURCE, resourceValues);
    let updatedResource = null;
    try {
      if (resourceValues.id) {
        updatedResource = await this.editResource(resourceFormatted, resourceValues.isCustomMedia, resourceValues.imageUrl);
        //this.fetchResourcesAndTags();
        dispatch(ResourceActions.updateReduxResource(updatedResource))
      } else {
        updatedResource = await this.createResource(resourceFormatted, resourceValues.isCustomMedia, resourceValues.imageUrl);
        dispatch(ResourceActions.updateReduxResource(updatedResource));
      }
      // Handle dependencies
      const resourceDependenciesData = resourceDependencies && resourceDependencies.content ? resourceDependencies.content : [];
      const dependenciesResourceIds = _.map(resourceDependenciesData, dependency => dependency.destination.id);
      const dependenciesResourcesToRemove = _.difference(dependenciesResourceIds, resourceValues.linkedResourcesIds);
      const dependenciesResourcesToAdd = _.difference(resourceValues.linkedResourcesIds, dependenciesResourceIds);
      this.onHandleDependencies(updatedResource, dependenciesResourcesToAdd, dependenciesResourcesToRemove);
      // Handle resource accessories
      if (resourceValues.resourceAccessoryIds) {
        this.onHandleResourceAccessories(updatedResource, resourceValues.resourceAccessoryIds, resourceValues.resourceAccessories);
      }
      dispatch(ResourceActions.fetchResourcesWorkspaces(0, 50));
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(ModalActions.showModal({
        modalType: 'SUCCESS_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="resourceSavedSuccess" /></h6>),
        },
      }));
      this.onCloseOperationalSection();
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_MODAL',
        modalProps: { type: 'DEFAULT_ERROR', defaultMessage: <Entity entity="errorSavingResource" /> },
      }));
    }
  }
  // Single function principle it's violated because fetchResources need a workaround
  async setFilter(field, value, forceSearch) {
    const { dispatch } = this.props;
    try {
      await dispatch(ResourceActions.setResourceFilter(field, value));
      if (forceSearch || !value || (value.length >= 2)) {
        if (forceSearch) {
          const { resources: { filters } } = this.props;
          dispatch(UtilsActions.setSpinnerVisibile(true));
          await this.fetchResourcesWithFilters({ ...filters });
          dispatch(UtilsActions.setSpinnerVisibile(false));
        }
        else {
          if (filterTimeout) clearTimeout(filterTimeout);
          filterTimeout = setTimeout(async () => {
            const { resources: { filters } } = this.props;
            this.setState({ viewLoading: true });
            this.fetchResourcesWithFilters({ ...filters });
            this.setState({ viewLoading: false });
          }, 500);
        }
        
      }
    } catch (error) {
      console.log(error)
      dispatch(UtilsActions.setSpinnerVisibile(false));
    }
  }

  async fetchResourcesWithFilters(filters) {
    this.setState({ viewLoading: true });
    await this.fetchResourcesAndTags(0, filters);
    this.setState({ viewLoading: false });
  }

  async resetResourcesFilters() {
    const { dispatch } = this.props;
    dispatch(UtilsActions.setSpinnerVisibile(true));
    await dispatch(ResourceActions.resetResourcesFilters());
    await dispatch(ResourceActions.resetResources());
    this.fetchResourceTypes()
    await this.fetchResourcesWithFilters({});
    dispatch(UtilsActions.setSpinnerVisibile(false));
  }

  onOpenResourcesInfo() {
    const formattedURL = localizeHelpCenterLink('bookey-add-on-manage-resources-and-reservations');
    window.open(formattedURL);
  }

  onSelectWorkspace(workspace) {
    this.setFilter('workspace', workspace, true);
  }

  async onSelectResourceType(value) {
    this.setFilter('typeId', value==="__ANY__"?undefined:value, true);
  }

  async onOpenResourceSharingSettings() {
    const { resource, dispatch } = this.props;
    try {
      dispatch(UtilsActions.setSpinnerVisibile(true));
      const subcompanies = await dispatch(ResourceActions.fetchSharedResources(0, 100, { sharedFromResourceId: resource.id}, false));
      dispatch(initialize('ResourceSharingForm', {
        subcompaniesIds: subcompanies && _.map(subcompanies, sub => sub.companyId),
      }))
      dispatch(ResourceActions.setSelectedResource({ ...resource, subCompaniesShared: subcompanies }))
      this.setState({ showResourceSharingSection: true });
      dispatch(UtilsActions.setSpinnerVisibile(false));
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="errorFetchingLockSharingOptions" /></h6>),
        },
      }));
    }
  }


  async onSaveResourceSharingOptions(sharingOptions) {
    const { dispatch, resource } = this.props;
    try {
      dispatch(UtilsActions.setSpinnerVisibile(true));
      for (const companyId of sharingOptions.subcompaniesIds) {
        const resourceSubcompanies = resource && resource.subCompaniesShared;
        if (resourceSubcompanies && !_.isEmpty(resourceSubcompanies)) {
          const alreadySharedWithCompany = _.find(resourceSubcompanies, subSharedCompany => subSharedCompany.companyId === companyId);
          if (!alreadySharedWithCompany) {
            try {
              await dispatch(ResourceActions.shareResource({ companyId, resourceId: resource.id }));
            } catch (error) {
            }
          }
        } else {
          try {
            await dispatch(ResourceActions.shareResource({ companyId, resourceId: resource.id }));
          } catch (error) {
          }
        }
      }
      const subcompanies = await dispatch(ResourceActions.fetchSharedResources(0, 100, { sharedFromResourceId: resource.id}, false));
      dispatch(change('ResourcesForm', 'subcompanies', subcompanies));
      dispatch(UtilsActions.setSpinnerVisibile(false));
      this.setState({ showResourceSharingSection: false });
      dispatch(ModalActions.showModal({
        modalType: 'SUCCESS_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="resourceShareSuccess" /></h6>),
        },
      }));
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="resourceShareError" /></h6>),
        },
      }));
    }
  }

  onTabChange(activeTab) {
    const { dispatch } = this.props;
    const selectedViewMode = activeTab === 0 ? VIEW_MODES.TABLE : VIEW_MODES.CARDS;
    this.setState({ activeTab });
    saveDataToLocalStorage('resourcesViewMode', selectedViewMode);
    if (activeTab === 0) {
      this.fetchResourcesAndTags();
    }
  }
  
  render() {
    const { resources, resources: { selectedResource }, resourcesTypes, resources: { workspaces }, themeName, dispatch, subcompanies } = this.props;
    const { isOperationalMode, viewLoading, showResourceSharingSection, activeTab } = this.state;
    const  { filters } = resources;
    const workspaceTitleLabel = dispatch(UtilsActions.getResourcesWorkspaceLabel());
    const workspaceOptions = [{ label: workspaceTitleLabel, value: '' }, ..._.map(_.compact(workspaces), workspace => ({ label: workspace, value: workspace }))];
    const isLuckeyLite = AbilityProvider.getAbilityHelper().hasLicenseType([LICENSE_TYPES.LITE]);
    return (
      <div>
        <PresentationalViewHeader
          themeName={themeName}
          onNewEntity={() => this.onNewResourceButtonClicked()}
          newEntityTitle="newResource"
          newEntityIconName="icon-simple-line-icons-user-following"
          onSearchReset={() => this.resetResourcesFilters()}
          onFilterChange={value => this.setFilter('name', value, false)}
          onSearchSubmit={() => this.fetchResourcesWithFilters({ ...filters })}
          onInfo={() => this.onOpenResourcesInfo()}
          onSelectOption={value => this.onSelectWorkspace(value)}
          selectableFieldName="status"
          selectableOptions={_.map(workspaceOptions, workspace =>
            <Entity
              key={workspace.value}
              componentClass={TranslatableOption}
              value={workspace.value}
              componentAttribute="text"
              entity={workspace.label}
            />)}
          onSelectResourceType={value => this.onSelectResourceType(value)}
          resourceTypeOptions={[
            (<Entity
              key={"anyResourceTypeFilter"}
              componentClass={TranslatableOption}
              value={"__ANY__"}
              componentAttribute="text"
              entity={"anyResourceTypeFilter"}
            />),
            ..._.map(_.sortBy(resourcesTypes, (type => type.name.toLowerCase())), type =>
              <option value={type.id} key={type.id}>
                {type.name}
              </option>
            )
          ]
          }
        />
        {resources.data && !_.isEmpty(resources.data.content) && !isLuckeyLite ? (
          <div style={{ top: 140, position: 'fixed', backgroundColor: 'white' }}>
            <Tabs
              value={activeTab}
              indicatorColor="primary"
              textColor="primary"
              onChange={(e, index) => this.onTabChange(index)}
            >
              <Tab icon={<ListViewIcon style={{ fontSize: 30 }} />} />
              <Tab icon={<CardViewIcon style={{ fontSize: 30 }} />} />
            </Tabs>
          </div>
        ) : null}
        {activeTab === 0 && !isLuckeyLite ? (
          <ResourcesTableView
            resources={resources.data}
            viewLoading={viewLoading}
            selectedResource={selectedResource}
            onFetchResourcesOnPage={page => this.fetchResourcesAndTags(page, resources.filters, false)}
            onNewEntity={() => this.onNewResourceButtonClicked()}
            onRefreshResources={() => this.fetchResourcesAndTags(0, resources.filters, false)}
            onSelectResource={resource => this.onResourceSelected(resource)}
            //onShowResourceOnMap={resource => this.onShowResourceOnMap(resource)}
          />
        ) : null}
        {activeTab === 1 || isLuckeyLite ? (
          <ResourceList
            resources={resources.data}
            viewLoading={viewLoading}
            selectedResource={selectedResource}
            onResourcePressed={resource => this.onResourceSelected(resource)}
            onLoadNextResources={page => this.fetchResourcesAndTags(page, resources.filters, true)}
            onNewEntity={() => this.onNewResourceButtonClicked()}
          />
        ) : null}
        <OperationalView
          themeName={themeName}
          isVisible={isOperationalMode}
          onClose={() => this.onCloseOperationalSection()}
          title={<Entity entity="sectionTitle" data={{ name: 'resourceOperationalSection' }} />}
          
        >
          <ResourcesOperationalSection
            subcompanies={subcompanies}
            onDeleteResource={resource => this.onDeleteResource(resource)}
            onSaveResource={resourceValues => this.onSaveResource(resourceValues)}
            onOpenResourceSharingSettings={() => this.onOpenResourceSharingSettings()}
            onSubmitResourceForm={() => dispatch(submit('ResourcesForm'))}
          />
        </OperationalView>
        <OperationalView
          themeName={themeName}
          isVisible={showResourceSharingSection}
          style={{ margin: 0, padding: 0 }}
          onClose={() => this.setState({ showResourceSharingSection: false })}
          title={<Entity entity="shareResource" />}
        >
          <ResourceSharingForm
            subcompanies={subcompanies}
            onSubmit={sharingOptions => this.onSaveResourceSharingOptions(sharingOptions)}
            onValidateForm={() => dispatch(submit('ResourceSharingForm'))}
          />
        </OperationalView>
      </div>
    );
  }
} 

export default Resources;
