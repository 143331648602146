import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import ListViewIcon from '@material-ui/icons/ViewList';
import CardViewIcon from '@material-ui/icons/ViewStream';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import moment from 'moment';
import qs from 'qs';
import React from 'react';
import { connect } from 'react-redux';
import { initialize } from 'redux-form';
import { DEFAULT_CREDENTIAL_TIME_FRAME, LANGUAGES, MATCH_TAG_MODE, PERMISSIONS, PERMISSION_ENTITIES, VIEW_MODES } from '../../_config/consts';
import * as formatter from '../../_config/formatter';
import { localizeHelpCenterLink } from '../../_config/utils.js';
import AccessProfilesListView from '../../components/AccessProfiles/AccessProfilesListView.jsx';
import AccessProfilesTableView from '../../components/AccessProfiles/AccessProfilesTableView.jsx';
import AccessProfilesIconCustom from '../../components/CustomIcons/AccessProfilesIconCustom.jsx';
import OperationalView from '../../components/OperationalView/OperationalView.jsx';
import PresentationalViewHeader from '../../components/PresentationalView/PresentationalViewHeader.jsx';
import AbilityProvider from '../../permissionsUtils/AbilityProvider.js';
import * as AccessProfilesActions from '../../redux/actions/accessProfiles.actions';
import * as CredentialActions from '../../redux/actions/credential.actions';
import * as ModalActions from '../../redux/actions/modal.actions';
import * as UtilsActions from '../../redux/actions/utils.actions';
import AccessProfilesOperationalSection from './AccessProfilesOperationalSection.jsx';


let filterTimeout;
@connect(state => ({
  credential: state.credentials.selectedCredential,
  lockTags: state.tags.lock.data,
  accessProfiles: state.accessProfiles,
  routing: state.router,
  languange: state.settings.language,
  viewLoading: state.utils.viewLoading,
  themeName: state.settings.items.theme.data.themeName
}))
class AccessProfilesView extends React.Component {

  constructor(props) {
    super(props);
    const cachedViewMode = localStorage.getItem('accessProfilesViewMode');
    this.state = {
      activeTab: cachedViewMode && cachedViewMode === VIEW_MODES.CARDS ? 1 : 0,
    };
  }

  async componentWillMount() {
    const { dispatch, routing } = this.props;
    const parsed = qs.parse(routing.location.search, { ignoreQueryPrefix: true });
    this.onFetchAccessProfiles();
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(AccessProfilesActions.setAccessProfilesFilter({}));
    dispatch(AccessProfilesActions.setAccessProfilesOperationalMode(false));
  }

  async onFetchAccessProfiles() {
    const { dispatch } = this.props;
    dispatch(UtilsActions.setViewLoading(true));
    try {
      await dispatch(AccessProfilesActions.fetchAccessProfilesWithUsers());
    } finally {
      dispatch(UtilsActions.setViewLoading(false));
    }
  }

  onNewAccessProfileButtonClicked() {
    const { dispatch } = this.props;
    dispatch(AccessProfilesActions.setSelectedAccessProfile({}));
    dispatch(initialize('AccessProfileForm', {
      timeIntervalFrom: moment().startOf('day').valueOf(),
      timeIntervalTo: moment().endOf('day').valueOf(),
      dateIntervalTimeFrom: moment().startOf('day').valueOf(),
      dateIntervalTimeTo: moment().endOf('day').valueOf(),
      daysOfTheWeek: [1, 2, 3, 4, 5, 6, 7],
      lockTagMatchingMode: MATCH_TAG_MODE.AT_LEAST_ONE_TAG,
      credentialTimeframe: DEFAULT_CREDENTIAL_TIME_FRAME,
    }));
    dispatch(AccessProfilesActions.setAccessProfilesOperationalMode(true));
    
  }


  onCloseOperationalSection() {
    const { dispatch } = this.props;
    dispatch(AccessProfilesActions.setSelectedAccessProfile({}));
    dispatch(AccessProfilesActions.setAccessProfilesOperationalMode(false));
  }

  onSetFilter(field, value) {
    const { dispatch } = this.props;
    dispatch(AccessProfilesActions.setAccessProfilesFilter(field, value));
    if (filterTimeout) clearTimeout(filterTimeout);
    filterTimeout = setTimeout(async () => {
      await this.onFetchAccessProfiles();
    }, 500);
  }

  onSearchReset() {
    const { dispatch } = this.props;
    dispatch(AccessProfilesActions.resetAccessProfilesFilters());
    this.onFetchAccessProfiles();
  }


  onExportElements(format) {
    const { dispatch } = this.props;
    dispatch(AccessProfilesActions.exportAccessProfiles(format));
  }
  
  onAccessProfilesListModeChange(activeTab) {
    const { dispatch } = this.props;
    const selectedViewMode = activeTab === 0 ? VIEW_MODES.TABLE : VIEW_MODES.CARDS;
    this.setState({ activeTab });
    AccessProfilesActions.setAccessProfilesViewMode(selectedViewMode);
    if (activeTab === 0) {
      dispatch(AccessProfilesActions.resetAccessProfilesPagination());
      dispatch(AccessProfilesActions.fetchAccessProfilesWithUsers());
    }
  }

  onFetchAccessProfilesOnPage(page) {
    const { dispatch } = this.props;
    dispatch(AccessProfilesActions.fetchAccessProfiles(page));
  }

  async onSelectAccessProfile(accessProfile) {
    const { dispatch } = this.props;
    dispatch(AccessProfilesActions.setSelectedAccessProfile(accessProfile));
    let formDefaultValues = accessProfile;
    const defaultAdditionalTimeRange = accessProfile.additionalTimeRange;
      if (defaultAdditionalTimeRange) {
        formDefaultValues = {
          ...formDefaultValues,
          additionalTimeRange_default: [formatter.formatInputData(formatter.TIME_RANGE, accessProfile.additionalTimeRange)],
        }
      }
    try {
      await dispatch(CredentialActions.fetchLocksByTags(accessProfile.lockTags, accessProfile.lockTagMatchingMode));
      await dispatch(AccessProfilesActions.fetchGuestsByAccessProfiles(accessProfile.id));

      dispatch(initialize('AccessProfileForm', formDefaultValues));
      dispatch(AccessProfilesActions.setAccessProfilesOperationalMode(true));
    } catch (error) {
      dispatch(AccessProfilesActions.setSelectedAccessProfile({}));
    }
  }


  onDeleteAccessProfile(accessProfileId) {
    const { dispatch } = this.props;
    const params = {
      modalType: 'CONFIRM_TO_CONTINUE_MODAL',
      modalProps: {
        title: 'confirmBeforeContinue',
        message: 'deleteAccessProfileConfirm',
        onConfirmText: <Entity entity="yes" />,
        onConfirm: () => this.onDeleteAccessProfileConfirmed(accessProfileId),
        onCancelText: <Entity entity="no" />,
        onCancel: () => dispatch(ModalActions.hideModal()),
      },
    };
    dispatch(ModalActions.showModal(params));
  }

  async onDeleteAccessProfileConfirmed(accessProfileId) {
    const { dispatch } = this.props;
    dispatch(UtilsActions.setSpinnerVisibile(true));
    try {
      await dispatch(AccessProfilesActions.deleteAccessProfile(accessProfileId));
      dispatch(UtilsActions.setSpinnerVisibile(false));
      this.onFetchAccessProfiles();
      dispatch(ModalActions.showModal({
        modalType: 'SUCCESS_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="accessProfileDeleteSuccess" /></h6>),
        },
      }));
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="accessProfileDeleteError" /></h6>),
        },
      }));
    }
  }

  async onCreateNewAccessProfile(accessProfileData) {
    const { dispatch } = this.props;
    try {
      dispatch(UtilsActions.setSpinnerVisibile(true));
      await dispatch(AccessProfilesActions.createAccessProfile(accessProfileData));
      dispatch(UtilsActions.setSpinnerVisibile(false));
      this.onFetchAccessProfiles();
      dispatch(AccessProfilesActions.setAccessProfilesOperationalMode(false));
      dispatch(AccessProfilesActions.setSelectedAccessProfile({}));
      dispatch(ModalActions.showModal({
        modalType: 'SUCCESS_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="accessProfileCreatedSuccessFully" /></h6>),
        },
      }));
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="accessProfileActionError" /></h6>),
        },
      }));
    }
  }

  onEditAccessProfile(accessProfileId) {
    const { dispatch } = this.props;
    const params = {
      modalType: 'CONFIRM_TO_CONTINUE_MODAL',
      modalProps: {
        title: 'confirmBeforeContinue',
        message: 'editAccessProfileConfirm',
        onConfirmText: <Entity entity="yes" />,
        onConfirm: () => this.onEditAccessProfileConfirm(accessProfileId),
        onCancelText: <Entity entity="no" />,
        onCancel: () => dispatch(ModalActions.hideModal()),
      },
    };
    dispatch(ModalActions.showModal(params));
  }

  async onEditAccessProfileConfirm(accessProfileData) {
    const { dispatch } = this.props;
    try {
      dispatch(UtilsActions.setSpinnerVisibile(true));
      await dispatch(AccessProfilesActions.updateAccessProfile(accessProfileData.id, accessProfileData));
      dispatch(UtilsActions.setSpinnerVisibile(false));
      this.onFetchAccessProfiles();
      dispatch(AccessProfilesActions.setAccessProfilesOperationalMode(false));
      dispatch(AccessProfilesActions.setSelectedAccessProfile({}));
      dispatch(ModalActions.showModal({
        modalType: 'SUCCESS_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="accessProfileUpdatedSuccessFully" /></h6>),
        },
      }));
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="accessProfileActionError" /></h6>),
        },
      }));
    }
  }


  onOpenInfo() {
    const helpLink = localizeHelpCenterLink('');
    window.open(helpLink);
  }

  onAccessProfileFormSubmit(accessProfileData) {
    if (accessProfileData && accessProfileData.id) {
      this.onEditAccessProfile(accessProfileData);
    } else {
      this.onCreateNewAccessProfile(accessProfileData);
    }
  }


  render() {
    const { accessProfiles: { isOperationalMode, selectedAccessProfile, data: { pagination } }, credential, language, form, lockTags, themeName, dispatch, viewLoading } = this.props;
    const { activeTab } = this.state;
    const numberOfAccessProfiles = pagination && pagination.totalElements ? pagination.totalElements : 0;
    const canCreateItem = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.ALL], PERMISSION_ENTITIES.ACCESS_PROFILES);
    const is12HoursFormat = language === LANGUAGES.ENGLISH;
    return (
      <div>
        <PresentationalViewHeader
          themeName={themeName}
          onFilterChange={value => this.onSetFilter('name', _.trim(value))}
          onNewEntity={canCreateItem ? () => this.onNewAccessProfileButtonClicked() : null}
          onSearchSubmit={() => this.onFetchAccessProfiles()}
          onSearchReset={() => this.onSearchReset()}
          searchGuestContainerStyle={{ width: 280 }}
          newEntityTitle="addAccessProfiles"
          searchPlaceholderEntityName="name"
          newEntityIconName="icon-simple-line-icons-user-following"
          isLoading={viewLoading}
          onInfo={() => this.onOpenInfo()}
          onExportElements={format => this.onExportElements(format)}
          numberOfElements={numberOfAccessProfiles}
          entitiesNumberSection={
            <div style={{ marginRight: 20, marginTop: 13, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <AccessProfilesIconCustom style={{ width: 22 }} />
              <h4 style={{ marginTop: 5 }}>{numberOfAccessProfiles}</h4>
            </div>
          }
        />
        <div style={{ top: 140, position: 'fixed', backgroundColor: 'white' }}>
          <Tabs
            value={activeTab}
            indicatorColor="primary"
            textColor="primary"
            onChange={(e, index) => this.onAccessProfilesListModeChange(index)}
          >
            <Tab icon={<ListViewIcon style={{ fontSize: 30 }} />} />
            <Tab icon={<CardViewIcon style={{ fontSize: 30 }} />} />
          </Tabs>
        </div>
        {activeTab === 0 ? (
          <AccessProfilesTableView
            canCreateItem={canCreateItem}
            onNewAccessProfile={() => this.onNewAccessProfileButtonClicked()}
            onSelectAccessProfile={accessProfile => this.onSelectAccessProfile(accessProfile)}
            onRefreshAccessProfiles={() => this.onFetchAccessProfiles()}
            onFetchAccessProfilesOnPage={page => this.onFetchAccessProfilesOnPage(page)}
            onDeleteAccessProfile={accessProfileId => this.onDeleteAccessProfile(accessProfileId)}
          />
          ) : null}
        {activeTab === 1 ? (
          <AccessProfilesListView
            canCreateItem={canCreateItem}
            onSelectAccessProfile={accessProfile => this.onSelectAccessProfile(accessProfile)}
            onNewAccessProfile={() => this.onNewAccessProfileButtonClicked()}
          />
          ) : null}
        <OperationalView
          themeName={themeName}
          isVisible={isOperationalMode}
          onClose={() => this.onCloseOperationalSection()}
          title={<Entity entity="sectionTitle" data={{ name: 'accessProfilesOperationalTitle' }} />}
          style={{ margin: 0, padding: 0 }}
        >
            <AccessProfilesOperationalSection
              onAccessProfileFormSubmit={accessProfileData => this.onAccessProfileFormSubmit(accessProfileData)}
              onDeleteAccessProfile={() => this.onDeleteAccessProfile(selectedAccessProfile.id)}
            />
        </OperationalView>
      </div>
    );
  }
} 

export default AccessProfilesView;
