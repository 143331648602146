import { IconButton } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/HelpOutline';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { connect } from 'react-redux';
import { initialize } from 'redux-form';
import AbilityProvider from '../../../permissionsUtils/AbilityProvider';
import TranslatableOption from '../../../components/forms/Fields/TranslatableOption.jsx';
import * as ModalActions from '../../../redux/actions/modal.actions';
import * as TagsManagementActions from '../../../redux/actions/tagsManagement.actions';
import * as UtilsActions from '../../../redux/actions/utils.actions';
import { GREEN, PERMISSIONS, PERMISSION_ENTITIES } from '../../../_config/consts';
import EmptyView from '../../EmptyView.jsx';
import SearchBarForm from '../../forms/SearchBarForm.jsx';
import ListLoadingView from '../../ListLoadingView.jsx';
import MDButton from '../../MDButton/MDButton.jsx';
import Permission from '../../Permission/Permission.jsx';
import TagManagementRow from '../TagManagementRow.jsx';
import TagsExplanationView from '../TagsExplanationView.jsx';

let filterTimeout;

@connect(state => ({
  tagsManagement: state.tagsManagement,
  viewLoading: state.utils.viewLoading,
  themeName: state.settings.items.theme.data.themeName,
}))
class LocksTagsManagementList extends React.Component {
  
  async componentDidMount() {
    const { dispatch } = this.props;
    await dispatch(TagsManagementActions.fetchLockTagsCategories());
    dispatch(TagsManagementActions.setOperationalMode(false));
    this.onFetchLockTags();
  }

  async onFetchLockTags() {
    const { dispatch } = this.props;
    try {
      dispatch(UtilsActions.setViewLoading(true));
      dispatch(TagsManagementActions.setSelectedTag(null));
      dispatch(TagsManagementActions.resetLocksTagsData());
      await dispatch(TagsManagementActions.fetchLocksSpecialTags());
      dispatch(UtilsActions.setViewLoading(false));
    } catch (error) {
      dispatch(UtilsActions.setViewLoading(false));
    }
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(TagsManagementActions.resetLocksTagsFilter());
  }

  onAppendLocksTags(page) {
    const { dispatch } = this.props;
    dispatch(TagsManagementActions.fetchAndAppendLocksSpecialTags(page));
  }

  onCloseOperationalSection() {
    const { dispatch } = this.props;
    dispatch(TagsManagementActions.setOperationalMode(false));
  }

  async onFilterLockTagsByCategory(value) {
    const { dispatch } = this.props;
    if (value==="__ANY__")
      await dispatch(TagsManagementActions.setLockTagsFilter('type', undefined));
    else 
      await dispatch(TagsManagementActions.setLockTagsFilter('type', value));
    await dispatch(TagsManagementActions.fetchLocksSpecialTags());
  }
  
  onFilterLockTags(text) {
    const { dispatch } = this.props;
    if (filterTimeout) clearTimeout(filterTimeout);
    filterTimeout = setTimeout(async () => {
      dispatch(TagsManagementActions.setLockTagsFilter('name', _.trim(text)));
      await dispatch(TagsManagementActions.fetchLocksSpecialTags());
    }, 300);
  }
  
  async onResetLockTagsFilter() {
    const { dispatch } = this.props;
    dispatch(UtilsActions.setViewLoading(true));
    try {
      dispatch(TagsManagementActions.resetLocksTagsFilter());
      dispatch(TagsManagementActions.resetLocksTagsData());
      await dispatch(TagsManagementActions.fetchLocksSpecialTags());
      dispatch(UtilsActions.setViewLoading(false));
    } catch (error) {
      dispatch(UtilsActions.setViewLoading(false));
    }
  }
  
  async onNewLockTag() {
    const { dispatch } = this.props;
    dispatch(UtilsActions.setSpinnerVisibile(true));
    dispatch(TagsManagementActions.setSelectedTag(null));
    dispatch(TagsManagementActions.resetTagLocksData());
    dispatch(initialize('TagForm', { }));
    await dispatch(TagsManagementActions.fetchLockTagsCategories());
    dispatch(UtilsActions.setSpinnerVisibile(false));
    dispatch(TagsManagementActions.setOperationalMode(true));
  }

  async onSelectTag(tag) {
    const { dispatch } = this.props;
    dispatch(UtilsActions.setSpinnerVisibile(true));
    await dispatch(TagsManagementActions.fetchLockTagsCategories());
    await dispatch(TagsManagementActions.fetchLocksByTag(tag.id));
    dispatch(TagsManagementActions.setSelectedTag(tag));
    dispatch(initialize('TagForm', { ...tag, type: { name: tag.type }, color: { hex: tag.color } }));
    dispatch(UtilsActions.setSpinnerVisibile(false));
    dispatch(TagsManagementActions.setOperationalMode(true));
  }

  onShowGuestTagHelpModal() {
    const { dispatch } = this.props;
    const params = {
      modalType: 'CONTENT_MODAL',
      modalProps: {
        title: <Entity entity="locksTagsInfoTitle" />,
        content: <TagsExplanationView tagType="locks" />,
        onOutsideClick: () => dispatch(ModalActions.hideModal()),
      },
    };
    dispatch(ModalActions.showModal(params));
  }

  render() {
    const { tagsManagement: { selectedTag, lockTags: { pagination: lockTagsPagination, content: lockTagsData }, lockTagsCategories: { content: lockTagsCategories } }, themeName, viewLoading } = this.props;
    
    const tagCategories = [
      (<Entity
        key={"anyTagCategoryFilter"}
        componentClass={TranslatableOption}
        value={"__ANY__"}
        componentAttribute="text"
        entity={"anyTagCategoryFilter"}
      />),
      ..._.map(_.sortBy(lockTagsCategories, (tag => tag.name.toLowerCase())), tag =>
        <option value={tag.name} key={tag.name}>
          {tag.name}
        </option>
      )
    ]
    
    return (
      <div>
        <div className="shadowed" style={{ padding: 5, paddingLeft: 15, backgroundColor: '#f0f0f0', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <SearchBarForm
            onFilterChange={text => this.onFilterLockTags(text)}
            onSearchReset={() => this.onResetLockTagsFilter()}
            onSelectLockTagCategory={(value) => {this.onFilterLockTagsByCategory(value)}}
            lockTagCategories={tagCategories}
            containerStyle={{ padding: 10 }}
          />
          <Permission do={[PERMISSIONS.CREATE]} on={PERMISSION_ENTITIES.SMART_LOCK_TAG} abilityHelper={AbilityProvider.getAbilityHelper()}>
            <MDButton
              backgroundColor={GREEN}
              containerStyle={{ margin: 0, marginLeft: 20, marginTop: 4 }}
              title={<Entity entity="newLockGroup" />}
              onClick={() => this.onNewLockTag()}
            />
          </Permission>
          <IconButton style={{ marginLeft: 10, marginTop: 5 }} onClick={() => this.onShowGuestTagHelpModal()}>
            <InfoIcon style={{ fontSize: 25 }} />
          </IconButton>
        </div>
        <div className="list-view details-section" style={{ width: '100%', top: 224, paddingBottom: 380 }}>
          <InfiniteScroll
            initialLoad={false}
            loadMore={() => this.onAppendLocksTags(lockTagsPagination.number + 1)}
            hasMore={lockTagsPagination.number + 1 < lockTagsPagination.totalPages}
            loader={!viewLoading ? <ListLoadingView /> : null}
            useWindow={false}
          >
            <div style={{ width: '40%' }}>
              {!_.isEmpty(lockTagsData) ?
                _.map(lockTagsData, tag =>
                  <TagManagementRow
                    key={tag.id}
                    tagRowType="locks"
                    isSelected={selectedTag && selectedTag.id === tag.id}
                    tag={tag}
                    themeName={themeName}
                    onClick={() => this.onSelectTag(tag)}
                  />)
                  : (
                    <div>
                      {!viewLoading ? (
                        <EmptyView
                          title="noGroupTags"
                          onNewEntity={() => this.onNewLockTag()}
                          newEntityTitle="addGroupTag"
                          subtitle="startAddGroups"
                          iconName="icon-simple-line-icons-user-following"
                        />) : <ListLoadingView />}
                    </div>
                  )
                }
            </div>
          </InfiniteScroll>
        </div>
      </div>
    );
  }
} 

export default LocksTagsManagementList;
