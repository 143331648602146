// @ts-nocheck
import AppBar from '@material-ui/core/AppBar';
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import UpdatedStatusIcon from '@material-ui/icons/Update';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import React from 'react';
import { connect } from 'react-redux';
import { change, initialize } from 'redux-form';
import { BLUE, CARD_TYPES, PERMISSION_ENTITIES, PERMISSIONS, VALIDATION_MODES, VALIDATION_MODES_SELECTION_OPTIONS } from '../../_config/consts';
import AbilityProvider from '../../permissionsUtils/AbilityProvider.js';
import * as CardActions from '../../redux/actions/card.actions';
import * as CredentialActions from '../../redux/actions/credential.actions';
import * as GuestActions from '../../redux/actions/guest.actions.js';
import * as TagActions from '../../redux/actions/tag.actions';
import * as UtilsActions from '../../redux/actions/utils.actions';
import * as ModalActions from '../../redux/actions/modal.actions.js'
import CardCredentialsList from '../Cards/CardCredentialList.jsx';
import CardEventsView from '../Cards/CardEventsView.jsx';
import ClipboardIconCustom from '../CustomIcons/ClipboardIconCustom.jsx';
import SmartLocksEventsIconCustom from '../CustomIcons/SmartLocksEventsIconCustom.jsx';
import HyperKeyCredentialsForm from '../forms/HyperKeyCredentialsForm.jsx';
import DeviceValidationPeriodView from '../Cards/DeviceValidationPeriodView.jsx';
import ClockCloudIconCustom from '../CustomIcons/ClockCloudIconCustom.jsx';
import { localizeHelpCenterLink } from '../../_config/utils.js';

const theme = createTheme({
  palette: {
    primary: { 500: BLUE },
  },
});

@connect(state => ({
  selectedHyperKey: state.cards.selectedHyperKey,
  credential: state.credentials.selectedCredential,
  userTags: state.tags.user.data,
  lockTags: state.tags.lock.data,
  language: state.settings.language,
  userToken: state.user.token,
  companyConfigurations: state.user.companyConfigurations,
}))
class HyperKeysOperationalSection extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      selectedTab: 0,
      cardTypes: CARD_TYPES,

    };
  }

  componentWillMount() {
    const { dispatch } = this.props;
    const includeSpecialTags = true;
    dispatch(TagActions.fetchGuestTags(includeSpecialTags, 'USER', 0, 100));
    dispatch(TagActions.fetchLockTags(includeSpecialTags, 0, 100));
  }

  async createHyperKey(values) {
    const { dispatch } = this.props;
    dispatch(UtilsActions.setSpinnerVisibile(true));
    try {
      if (values.deviceBackendId) {
        await dispatch(CardActions.updateKey(values));
      } else {
        await dispatch(CardActions.createCard(values, true));
      }
    } finally {
      dispatch(UtilsActions.setSpinnerVisibile(false));
    }
  }

  fetchGuestsByTags(page, append) {
    const { dispatch } = this.props;
    dispatch(CredentialActions.cancelFetchGuestsByTags());
    dispatch(CredentialActions.fetchGuestsByTagsStardardCredentialsForm('HYPER_KEY', page, append));
  }

  fetchLocksByTags(page, append) {
    const { dispatch } = this.props;
    dispatch(CredentialActions.cancelFetchLocksByTags());
    dispatch(CredentialActions.fetchLocksByTagsStardardCredentialsForm('HYPER_KEY', page, append));
  }

  filterGuestTags(value) {
    const { dispatch } = this.props;
    const tagsType = 'USER';
    dispatch(TagActions.cancelFetchGuestTagsByFilter());
    dispatch(TagActions.fetchGuestTagsByFilter(value, 50, true, tagsType));
  }

  filterLockTags(value) {
    const { dispatch } = this.props;
    dispatch(TagActions.cancelFetchLockTagsByFilter());
    dispatch(TagActions.fetchLockTagsByFilter(value, 200, true));
  }

  async onSaveKeyNotes(keyId, notes) {
    const { dispatch } = this.props;
    dispatch(UtilsActions.setSpinnerVisibile(true));
    try {
      await dispatch(CardActions.updateKeyNotes(keyId, notes));
      dispatch(UtilsActions.setSpinnerVisibile(false));
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisibile(false));
    }
  }

  closeOperationalSection() {
    const { dispatch } = this.props;
    dispatch(CardActions.setOperationalMode(false));
  }

  appendCardCredentials(page) {
    const { dispatch, key } = this.props;
    dispatch(CardActions.fetchCardCredentials(key, page, true));
  }

  selectTab(index, canReadCredential) {
    const { dispatch, selectedHyperKey } = this.props;
    this.setState({ selectedTab: index });
    if (index === 2 || (canReadCredential && index === 1)) {
      const validationDTO = {
        validationPeriod: selectedHyperKey.validationPeriod,
        validationMode: selectedHyperKey.validationMode !== VALIDATION_MODES.ALWAYS_VALIDATED && selectedHyperKey.validationMode !== 'DEFAULT' ? VALIDATION_MODES_SELECTION_OPTIONS.TIME_CONSTRAINED_VALIDATION : selectedHyperKey.validationMode,
        validationPeriodTimeUnit: selectedHyperKey.validationMode,
      };
      dispatch(initialize('DeviceValidationPeriodViewForm', validationDTO));
    }
  }

  async onCreateAnonymousUserForF9000(userData) {
    const { dispatch } = this.props;
    dispatch(UtilsActions.setSpinnerVisibile(true));
    try {
      const newGuest = await dispatch(GuestActions.createGuest(userData));
      dispatch(UtilsActions.setSpinnerVisibile(false));
      const guestTagId = newGuest && newGuest.userTag && newGuest.userTag.id;
      if (guestTagId) {
        await dispatch(CredentialActions.fetchGuestsByTags({ id: guestTagId }, 'AT_LEAST_ONE_TAG'));
        const guestTagDetails = await dispatch(TagActions.fetchGuestTagDetails(guestTagId));
        dispatch(change('HyperKeyCredentialsForm', 'guestTags', guestTagDetails));
      }
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisibile(false));
    }
  }

  async onUpdateValidationPeriod(validationDTO) {
    const { dispatch , selectedHyperKey } = this.props;
    try {
      dispatch(UtilsActions.setSpinnerVisibile(true));
      await dispatch(CardActions.updateStandardDeviceValidationMode({ ...validationDTO, id: selectedHyperKey.id }));
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(ModalActions.showModal({
        modalType: 'SUCCESS_ALERT',
        modalProps: {
          anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
          message: (<h6 className="snack-title"><Entity entity="modalMessage" data={{ modal: 'credentialUpdated' }} /></h6>)
        },
      }));
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_ALERT',
        modalProps: {
          anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
          message: (<h6 className="snack-title"><Entity entity="credentialUpdatedError" /></h6>),
        },
      }));
    }
  }

  onOpenValidationInfo() {
    const helpURL = localizeHelpCenterLink('f9000-keys-validation-period');
    window.open(helpURL);
  }

  render() {
    const { userTags, lockTags, selectedHyperKey, credential, onDeleteHyperKeyRequest } = this.props;
    const { selectedTab } = this.state;
    const canReadCredential = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.READ], PERMISSION_ENTITIES.CREDENTIAL);
    const canReadSmartLocksEvents = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.READ], PERMISSION_ENTITIES.LOG);
    return (
      <MuiThemeProvider theme={theme}>
        <div>
          <AppBar position="sticky" color="default">
            <Tabs
              value={selectedTab}
              indicatorColor="primary"
              textColor="primary"
              scrollable
              onChange={(e, index) => this.selectTab(index, canReadCredential)}
              style={{ paddingLeft: 25 }}
            >
              <Tab icon={<ClipboardIconCustom style={{ width: 26 }} />} label={<h5 style={{ margin: 0, fontWeight: 'bold' }}><Entity entity="cardInformation" /></h5>} />
              {selectedHyperKey && selectedHyperKey.id && canReadCredential &&
                <Tab icon={<UpdatedStatusIcon style={{ fontSize: 26 }} />} label={<h5 style={{ margin: 0, fontWeight: 'bold' }}><Entity entity="cardStatus" /></h5>} />
              }
              {selectedHyperKey && selectedHyperKey.id && (
                <Tab icon={<ClockCloudIconCustom style={{ width: 26 }} />} label={<h5 style={{ margin: 0, fontWeight: 'bold' }}><Entity entity="validationInfo" /></h5>} />
              )}
              {selectedHyperKey && selectedHyperKey.id && canReadSmartLocksEvents && (
                <Tab icon={<SmartLocksEventsIconCustom style={{ width: 26 }} />} label={<h5 style={{ margin: 0, fontWeight: 'bold' }}><Entity entity="guestSmartLocksLogs" /></h5>} />
              )}
            </Tabs>
          </AppBar>
          <div style={{ marginBottom: 500, padding: 20, display: selectedTab === 0 ? 'block' : 'none' }}>
            <HyperKeyCredentialsForm
              card={selectedHyperKey}
              availableUserTags={userTags}
              availableLockTags={lockTags}
              guestsFilteredByTags={credential.guestsFilteredByTags}
              locksFilteredByTags={credential.locksFilteredByTags}
              onFetchMoreLocks={page => this.fetchLocksByTags(page, true)}
              onFetchMoreGuests={page => this.fetchGuestsByTags(page, true)}
              onGuestInputChange={value => this.filterGuestTags(value)}
              onGuestTagsChange={() => this.fetchGuestsByTags()}
              onLockInputChange={value => this.filterLockTags(value)}
              onLockTagsChange={() => this.fetchLocksByTags()}
              onLockFilterModeChange={() => this.fetchLocksByTags()}
              onSubmit={values => this.createHyperKey(values)}
              onDeleteCard={selectedHyperKey && selectedHyperKey.id ? () => onDeleteHyperKeyRequest(selectedHyperKey) : null}
              onSaveKeyNotes={(notes) => this.onSaveKeyNotes(selectedHyperKey.id, notes)}
              onCreateAnonymousUserForF9000={userData => this.onCreateAnonymousUserForF9000(userData)}
            />
          </div>
          {selectedHyperKey && selectedHyperKey.id && canReadCredential && selectedTab === 1 && (
            <div style={{ padding: 20 }}>
              <CardCredentialsList
                credentials={selectedHyperKey.credentials}
                appendCardCredentials={page => this.appendCardCredentials(page)}
              />
            </div>
          )}
          {selectedHyperKey && selectedHyperKey.id && (selectedTab === 2 || (!canReadCredential && selectedTab === 1)) ? (
            <DeviceValidationPeriodView
              selectedDevice={selectedHyperKey}
              onOpenValidationInfo={() => this.onOpenValidationInfo()}
              onSubmit={validationPeriodDTO => this.onUpdateValidationPeriod(validationPeriodDTO)}
            />
          ) : null}
          {selectedHyperKey && selectedHyperKey.id && (selectedTab === 3 || (!canReadCredential && selectedTab === 2)) ? (
            <CardEventsView
              card={selectedHyperKey}
            />
          ) : null}
        </div>
      </MuiThemeProvider>
    );
  }
} 

export default HyperKeysOperationalSection;
