import { ctx as L20NContext, Entity } from '@sketchpixy/rubix/lib/L20n';
import { withStyles } from '@material-ui/core';
import moment from 'moment';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import * as TicketActions from '../../redux/actions/ticket.actions';
import * as UtilsActions from '../../redux/actions/utils.actions';
import CustomField from '../forms/Fields/CustomField.jsx';
import DateRangeView from '../forms/Fields/DatePickers/DateRangePicker/DateRangeView.jsx';
import MDButton from '../MDButton/MDButton.jsx';
import { GREEN, TICKETS_PRIORITY_FILTER_OPTIONS, TICKETS_STATUS_FILTER_OPTIONS } from '../../_config/consts.js';
import TranslatableOption from '../forms/Fields/TranslatableOption.jsx';
import SimpleExportMenu from '../ExportMenus/SimpleExportMenu.jsx';

const styles = theme => ({
  searchInput: {
    border: "1px solid rgb(204, 204, 204) !important"
  },
});

const validate = (values) => {
  const errors = {};
  return errors;
};


let filterTimeout;
@reduxForm({ form: 'TicketsFilters', validate })
@connect(state => ({ form: state.form.TicketsFilters, tickets: state.tickets,  }))
class TicketsFilters extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      
    }
  }
  
  async onSetFilter(field, value, useTimeout) {
    const { dispatch } = this.props;
    if (value && ((_.isArray(value) && !_.isEmpty(value)) || (_.isString(value) && value.length >= 2) || (_.isNumber(value) && value>0))) {
      await dispatch(TicketActions.setFilter(field, value));
      if (useTimeout) {
        if (filterTimeout) clearTimeout(filterTimeout);
        filterTimeout = setTimeout(async () => {
          await this.fetchTickets()
        }, 500);
      }
      else {
        dispatch(UtilsActions.setSpinnerVisibile(true));
        await this.fetchTickets()
        dispatch(UtilsActions.setSpinnerVisibile(false));
      }
    } else {
      if (!useTimeout)
        dispatch(UtilsActions.setSpinnerVisibile(true));
      await dispatch(TicketActions.setFilter(field, undefined));
      await this.fetchTickets()
      if (!useTimeout)
        dispatch(UtilsActions.setSpinnerVisibile(false));
    }
  }

  async fetchTickets() {
    const { dispatch, fromTicketAnalytics } = this.props;
    if (fromTicketAnalytics)
      await dispatch(TicketActions.fetchAllTickets());
    else
      await dispatch(TicketActions.fetchTickets());
  }

  onSelectStatus(value) {
    let filterValue = value;
    if (value === 'ALL') filterValue = undefined;
    this.onSetFilter('status', filterValue, false);
  }

  onSelectPriority(value) {
    let filterValue = value;
    if (value === 'ALL') filterValue = undefined;
    this.onSetFilter('priority', filterValue, false);
  }

  onSelectCategory(value) {
    let filterValue = value;
    if (value === "0") filterValue = undefined;
    else filterValue = [filterValue]
    this.onSetFilter('categoryIds', filterValue, false);
  }

  onSelectAssignee(value) {
    let filterValue = value;
    if (value === 'ALL' || isNaN(value)) filterValue = undefined;
    this.onSetFilter('managerId', filterValue, false);
  }

  onDatesChange(fromDate, toDate) {
    const { fromTicketAnalytics } = this.props;
    const startDate = fromDate ? moment(fromDate).startOf('day').valueOf() : fromTicketAnalytics ? moment().startOf('month').valueOf() : undefined;
    const endDate = toDate ? moment(toDate).endOf('day').valueOf() : fromTicketAnalytics ? moment().endOf('month').valueOf() : undefined;
    if (startDate)
      this.onSetFilter('fromDate', startDate, false);
    if (endDate)
      this.onSetFilter('toDate', endDate, false);
    if (startDate===undefined&&endDate===undefined) {
      this.onSetFilter('fromDate', undefined, false);
      this.onSetFilter('toDate', undefined, false);
    }
  }

  async resetFilters() {
    const { onResetFilters } = this.props;
    onResetFilters();
  }

  render() {
    const { fromTicketAnalytics, tickets, classes, categoriesArray, themeName, onExportElements, onOpenNewTicket } = this.props;
    const { } = this.state;
    const themeClass = `list-view-header bg-${themeName}-theme`;
    return (
      <div className={themeClass} style={{ display: 'flex', alignItems: 'center', width: '100%', height: 120 }}>
        <div>
          <DateRangeView
            id="ticket-date-selector"
            startDateId="ticket-date-selector-start"
            endDateId="ticket-date-selector-end"
            startDate={tickets?.data?.filters?.fromDate ? moment(tickets.data.filters.fromDate).startOf('day') : fromTicketAnalytics ? moment().startOf('month') : null}
            endDate={tickets?.data?.filters?.toDate ? moment(tickets.data.filters.toDate).startOf('day') : fromTicketAnalytics ? moment().endOf('month') : null}
            noBorder={false}
            endDatePlaceholderText="--/--/--"
            startDatePlaceholderText="--/--/--"
            minimumNight={0}
            style={{ marginTop: 50 }}
            isOutsideRange={day => moment(day).isAfter(moment())}
            onDatesChange={({ startDate, endDate }) => this.onDatesChange(startDate, endDate)}
            onChange={({ startDate, endDate }) => this.onDatesChange(startDate, endDate)}
            onResetOptionSelection={() => this.onDatesChange(null, null)}
            helpersOptions={[
              {
                title: L20NContext.getSync('today'),
                startDate: moment().startOf('day'),
                endDate: moment().endOf('day'),
              },
              {
                title: L20NContext.getSync('thisWeek'),
                startDate: moment().startOf('week'),
                endDate: moment().endOf('week'),
              },
              {
                title: L20NContext.getSync('thisMonth'),
                startDate: moment().startOf('month'),
                endDate: moment().endOf('month'),
              },
            ]}
          />
        </div>
        {fromTicketAnalytics?null:(
          <Entity
            componentClass={Field}
            name="type"
            type="text"
            componentAttribute="placeholder"
            component={CustomField}
            className={classes.searchInput}
            entity={"searchTicketByTitle"}
            onHandleChange={value => this.onSetFilter('search', _.trim(value), true)}
            containerStyle={{ marginRight: 20, marginLeft: 20, marginBottom: -12, marginTop: 3, width: 220 }}
          />
        )}
        <Field
          id="selectStatus"
          name={"ticketStatus"}
          title={''}
          component={CustomField}
          className="form-control-select"
          componentClass="select"
          onHandleChange={value => this.onSelectStatus(value)}
          containerStyle={{ marginRight: 20, marginLeft: fromTicketAnalytics?20:10, marginBottom: -12, marginTop: 3 }}
        >
          {_.map(TICKETS_STATUS_FILTER_OPTIONS, status =>
          <Entity
            key={status.name}
            componentClass={TranslatableOption}
            value={status.value}
            componentAttribute="text"
            entity={status.label}
          />)}
        </Field>
        <Field
          id="selectPriority"
          name={"ticketPriority"}
          title={''}
          component={CustomField}
          className="form-control-select"
          componentClass="select"
          onHandleChange={value => this.onSelectPriority(value)}
          containerStyle={{ marginRight: 20, marginLeft: 10, marginBottom: -12, marginTop: 3 }}
        >
          {_.map(TICKETS_PRIORITY_FILTER_OPTIONS, priority =>
          <Entity
            key={priority.name}
            componentClass={TranslatableOption}
            value={priority.value}
            componentAttribute="text"
            entity={priority.label}
          />)}
        </Field>
        {!fromTicketAnalytics ? (
          <Field
            id="selectCategory"
            name={"ticketCategory"}
            title={''}
            component={CustomField}
            className="form-control-select"
            componentClass="select"
            onHandleChange={value => this.onSelectCategory(value)}
            containerStyle={{ marginRight: 20, marginLeft: 10, marginBottom: -12, marginTop: 3 }}
          >
            {_.map(categoriesArray, category =>
            <Entity
              key={category.id}
              componentClass={TranslatableOption}
              value={category.id}
              componentAttribute="text"
              entity={category.label}
            />)}
          </Field>
        ) : null}
        {/* <Field
          id="selectAssignee"
          name={"ticketAssignee"}
          component={CustomField}
          className="form-control-select"
          componentClass="select"
          onHandleChange={value => this.onSelectAssignee(value)}
          containerStyle={{ marginRight: 0, marginLeft: 10, marginBottom: -12, marginTop: 3, width: 200 }}
        >
          {_.map([{id:undefined, label:'allTicketAssignee', username:'ALL'}, ...tickets.ticketManagers.content.filter(e=>e.username)], assignee =>
          <Entity
            key={assignee.id}
            componentClass={TranslatableOption}
            value={assignee.id}
            componentAttribute="text"
            entity={assignee.label?assignee.label:assignee.username}
          />)}
        </Field> */}
        <MDButton
          containerStyle={{ margin: 0, marginTop: 5, marginLeft: 10 }}
          backgroundColor="#D32F2F"
          title={<Entity entity="resetFilters" />}
          onClick={() => this.resetFilters()}
        />
        <MDButton
          onClick={() => onOpenNewTicket()}
          backgroundColor={GREEN}
          containerStyle={{ margin: 0, marginTop: 5, marginLeft: 20 }}
          title={<Entity entity="addTicket" />}
        />
        <SimpleExportMenu
          titleContainerStyle={{ marginLeft: 20, marginTop: 5 }}
          onExportLogs={format => onExportElements(format)}
        />
      </div>
    );
  }
} 

export default withStyles(styles)(TicketsFilters);
